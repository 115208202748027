export const multiply = (a, b) => {
    return a.map((row) => b[0].map((_, colIndex) => row.reduce((sum, el, rowIndex) => sum + el * b[rowIndex][colIndex], 0)));
  };
  export const transpose = (matrix) => {
    return matrix[0].map((_, colIndex) => matrix.map((row) => row[colIndex]));
  };
  export const invert = (matrix) => {
    const size = matrix.length;
    const identity = Array.from({ length: size }, (_, i) => Array.from({ length: size }, (_, j) => (i === j ? 1 : 0)));
    const augmented = matrix.map((row, i) => [...row, ...identity[i]]);
    for (let i = 0; i < size; i++) {
      const factor = augmented[i][i];
      for (let j = 0; j < augmented[i].length; j++) {
        augmented[i][j] /= factor;
      }
      for (let k = 0; k < size; k++) {
        if (k !== i) {
          const factor = augmented[k][i];
          for (let j = 0; j < augmented[k].length; j++) {
            augmented[k][j] -= factor * augmented[i][j];
          }
        }
      }
    }
    return augmented.map((row) => row.slice(size));
  };