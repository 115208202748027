import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import "../assets/styles/Exercise.css";
import Modal from "react-bootstrap/Modal";
// import { useLocation } from "react-router-dom";
class WorkoutProgram {
  constructor(name, age, weight, frequency_sets, frequency_reps) {
    this.name = name;
    this.age = age;
    this.weight = weight;
    this.frequency_sets = frequency_sets;
    this.exercise_sets = 4;
    this.frequency_reps = frequency_reps;
    this.muscles = ["middle back", "lower back", "glutes", "quadriceps", "chest", "hamstrings", "lats", "shoulders", "traps", "triceps", "biceps", "abdominals", "abductors", "adductors", "neck", "forearms", "calves"];
    this.muscleGrouping = ["middle back, lats, traps, biceps, forearms", "lower back, glutes, hamstrings", "chest, shoulders, triceps", "abdominals, neck", "quadriceps, abductors, adductors, calves", "rest", "rest"];
    this.split = [
      {
        day: "Day 1",
        muscles: [],
        exercises: [],
      },
      {
        day: "Day 2",
        muscles: [],
        exercises: [],
      },
      {
        day: "Day 3",
        muscles: [],
        exercises: [],
      },
      {
        day: "Day 4",
        muscles: [],
        exercises: [],
      },
      {
        day: "Day 5",
        muscles: [],
        exercises: [],
      },
      {
        day: "Day 6",
        muscles: [],
        exercises: [],
      },
      {
        day: "Day 7",
        muscles: [],
        exercises: [],
      },
    ];
    this.schedule = ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6", "Day 7"];
    this.exercises = [];
    this.grouping_history = [];
    this.schedule_history = [];
    let msec = Date.now();
    let startDate = new Date(msec).toISOString();
    this.create_date = startDate;
  }
  addMuscle(muscle) {
    this.muscles.push(muscle);
  }
  removeMuscle(muscle) {
    this.muscles = this.muscles.filter((a) => a !== muscle);
  }
  getMuscles() {
    return this.muscles;
  }
}
const Exercise = () => {
  const [collectionMain, setCollection] = useState([]);
  const [selectedObj, setSelectedObj] = useState({});
  const [collectionUser, setUserCollection] = useState({});
  const [collectionMuscles, setCollectionPrimaryMuscles] = useState([]);
  const [currentMuscles, setCurrentPrimaryMuscles] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showsaved, setShowSaved] = useState(false);

  const [collectionForce, setCollectionForce] = useState([]);
  const [currentForce, setCurrentForce] = useState([]);

  const [collectionLevel, setCollectionLevel] = useState([]);
  const [currentLevel, setCurrentLevel] = useState([]);

  const [collectionMechanic, setCollectionMechanic] = useState([]);
  const [currentMechanic, setCurrentMechanic] = useState([]);

  const [collectionEquipment, setCollectionEquipment] = useState([]);
  const [currentEquipment, setCurrentEquipment] = useState([]);

  const [collectionCategory, setCollectionCategory] = useState([]);
  const [currentCategory, setCurrentCategory] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const PerPage = 30;
  const totalPages = Math.ceil((collectionMain?.length || 0) / PerPage);
  const maxPagesToShow = 3;
  // const location = useLocation();
  // const [currentUserKey, setCurrentUserKey] = useState({});

  // useEffect(() => {
  //   const GetKey = async (authorization_code) => {
  //     try {
  //       const response = await axios.post(`/GetDexKey/${authorization_code}`);
  //       let responseData = response.data;
  //       setCurrentUserKey(responseData);
  //       localStorage.setItem("DexUser", JSON.stringify(responseData));
  //     } catch (error) {
  //       console.error("Error fetching key:", error);
  //     }
  //   };
  //   let authorization_code = new URLSearchParams(location.search).get("code");
  //   if (authorization_code) {
  //     GetKey(authorization_code);
  //   }
  //   else if (localStorage.getItem("DexUser")) {
  //     setCurrentUserKey(JSON.parse(localStorage.getItem("DexUser")));
  //   }
  // }, [location]);
  useEffect(() => {
    const GetJson = async () => {
      const response = await axios.get(`assets/exercises.json`);
      let data = response.data;
      setCollection(data);
      setCollectionForce([...new Set(data.map((g) => g.force).filter((g) => g !== null))]);
      setCollectionLevel([...new Set(data.map((g) => g.level))]);
      setCollectionMechanic([...new Set(data.map((g) => g.mechanic).filter((g) => g !== null))]);
      setCollectionEquipment([...new Set(data.map((g) => g.equipment).filter((g) => g !== null))]);
      setCollectionCategory([...new Set(data.map((g) => g.category))]);
      setCollectionPrimaryMuscles([...new Set([...data.flatMap((g) => g.primaryMuscles.map((g) => g)), ...data.flatMap((g) => g.secondaryMuscles.map((g) => g))])]);
      if (localStorage.getItem("workoutProgram")) {
        let user = JSON.parse(localStorage.getItem("workoutProgram"));
        if (user.split) {
          if (user.split[0].muscles.length === 0) {
            user.split.forEach((split, idx) => {
              split.muscles = user.muscleGrouping[idx].split(", ");
            });
          }
          if (!user.exercise_sets) {
            user.exercise_sets = 4;
          }
        } else {
          user.split = [
            {
              day: "Day 1",
              muscles: [],
              exercises: [],
            },
            {
              day: "Day 2",
              muscles: [],
              exercises: [],
            },
            {
              day: "Day 3",
              muscles: [],
              exercises: [],
            },
            {
              day: "Day 4",
              muscles: [],
              exercises: [],
            },
            {
              day: "Day 5",
              muscles: [],
              exercises: [],
            },
            {
              day: "Day 6",
              muscles: [],
              exercises: [],
            },
            {
              day: "Day 7",
              muscles: [],
              exercises: [],
            },
          ];
          user.split.forEach((split, idx) => {
            split.muscles = user.muscleGrouping[idx].split(", ");
          });
        }

        setUserCollection(user);
      }
    };
    GetJson();
  }, []);
  const getVisiblePages = () => {
    const pages = [];
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
    if (endPage - startPage + 1 < maxPagesToShow && totalPages > maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      setSelectedObj([]);
    }
  };
  const selectItem = (Id) => {
    if (!selectedObj.name || Id !== selectedObj.name) {
      const selected = collectionMain.find((g) => g.name === Id);
      setSelectedObj(selected);
      setShow1(true);
    } else {
      setSelectedObj([]);
    }
  };
  const handleCollectionChange = (option, item) => {
    if (item === "") {
      switch (option) {
        case "force":
          setCurrentForce([]);
          break;
        case "level":
          setCurrentLevel([]);
          break;
        case "mechanic":
          setCurrentMechanic([]);
          break;
        case "equipment":
          setCurrentEquipment([]);
          break;
        case "muscles":
          setCurrentPrimaryMuscles([]);
          break;
        case "category":
          setCurrentCategory([]);
          break;
        default:
          return;
      }
      return;
    }
    switch (option) {
      case "force":
        setCurrentForce((prevSelected) => {
          let updateList;
          if (prevSelected.includes(item)) {
            updateList = prevSelected.filter((g) => g !== item);
          } else {
            updateList = [...prevSelected, item];
          }
          return updateList;
        });
        break;
      case "level":
        setCurrentLevel((prevSelected) => {
          let updateList;
          if (prevSelected.includes(item)) {
            updateList = prevSelected.filter((g) => g !== item);
          } else {
            updateList = [...prevSelected, item];
          }
          return updateList;
        });
        break;
      case "mechanic":
        setCurrentMechanic((prevSelected) => {
          let updateList;
          if (prevSelected.includes(item)) {
            updateList = prevSelected.filter((g) => g !== item);
          } else {
            updateList = [...prevSelected, item];
          }
          return updateList;
        });
        break;
      case "equipment":
        setCurrentEquipment((prevSelected) => {
          let updateList;
          if (prevSelected.includes(item)) {
            updateList = prevSelected.filter((g) => g !== item);
          } else {
            updateList = [...prevSelected, item];
          }
          return updateList;
        });
        break;
      case "muscles":
        setCurrentPrimaryMuscles((prevSelected) => {
          let updateList;
          if (prevSelected.includes(item)) {
            updateList = prevSelected.filter((g) => g !== item);
          } else {
            updateList = [...prevSelected, item];
          }
          return updateList;
        });
        break;
      case "category":
        setCurrentCategory((prevSelected) => {
          let updateList;
          if (prevSelected.includes(item)) {
            updateList = prevSelected.filter((g) => g !== item);
          } else {
            updateList = [...prevSelected, item];
          }
          return updateList;
        });
        break;
      default:
        return;
    }
  };
  const updateCollection = useCallback((items, currentForce, currentLevel, currentMechanic, currentEquipment, currentPrimaryMuscles, currentCategory) => {
    var newCollection = [];

    newCollection = items.filter((elem) => {
      const categoryMatch = currentCategory.length === 0 || currentCategory.some((a) => elem.category === a);
      const primaryMusclesMatch =
        currentPrimaryMuscles.length === 0 ||
        currentPrimaryMuscles.some((a) => {
          const g1 = elem.primaryMuscles.flatMap((g) => g);
          const g2 = elem.secondaryMuscles.flatMap((g) => g);
          return g1.includes(a) || g2.includes(a);
        });
      const levelMatch = currentLevel.length === 0 || currentLevel.some((a) => elem.level === a);
      const mechanicMatch = currentMechanic.length === 0 || currentMechanic.some((a) => elem.mechanic === a);
      const equipmentMatch = currentEquipment.length === 0 || currentEquipment.some((a) => elem.equipment === a);
      const forceMatch = currentForce.length === 0 || currentForce.some((a) => elem.force === a);

      return categoryMatch && primaryMusclesMatch && levelMatch && mechanicMatch && equipmentMatch && forceMatch;
    });

    if (newCollection.length === 0) {
      newCollection = items;
    }

    return [...new Set(newCollection)];
  }, []);
  const bodymuscle = (muscles) => {
    let svg = (
      <svg id="bodymuscle" xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 68.587668 92.604164">
        <path className="head" style={{ opacity: "1", fill: "black" }} d="m 11.671635,6.3585449 -0.0482,-2.59085 4.20648,-2.46806 4.42769,2.95361 -0.0405,1.94408 0.24197,-3.34467 -2.03129,-2.31103004 -2.84508,-0.51629 -2.20423,0.52915 -1.9363,2.63077004 z"></path>
        <path className="face" style={{ opacity: "1", fill: "black" }} d="m 19.748825,6.7034949 0.0203,-2.20747 -3.96689,-2.7637 -3.74099,2.23559 -0.006,2.63528 -0.60741,0.0403 0.27408,1.82447 0.97635,0.33932 0.44244,2.1802901 1.82222,2.06556 2.03518,-0.0607 1.79223,-1.94408 0.35957,-2.2406601 0.97616,-0.33932 0.25159,-1.78416 z"></path>
        <path className="neck" style={{ opacity: "1", fill: "black" }} d="m 13.304665,11.910505 1.64975,2.35202 0.74426,2.62159 -1.73486,-1.38354 -0.86649,-2.97104 z m 5.08047,0 -1.64975,2.35202 -0.74538,2.62234 1.73486,-1.38354 0.86649,-2.97104 z"></path>
        <path
          className="shoulders"
          style={{ opacity: "1", fill: "black" }}
          d="m 19.047795,13.248365 3.55748,1.97916 0.72653,-0.35074 z m -0.107,0.43288 -0.37119,1.73073 2.1846,0.53561 1.40116,-0.49436 z m 3.98151,1.97595 0.75814,-0.41 2.40806,1.66799 1.17364,1.50707 0.62662,1.5626 -0.0464,3.70194 -1.3284,-1.72153 0.0407,-2.59376 -0.48842,-0.50049 c 0,0 -3.09778,-3.19058 -3.14371,-3.21401 z m -0.2409,0.10873 c -0.001,0.0525 3.32987,3.54733 3.32987,3.54733 l 0.10067,3.10396 -1.15426,-1.97782 -2.22547,-0.94804 -1.56576,-2.88481 z"
        ></path>
        <path
          className="shoulders"
          style={{ opacity: "1", fill: "black" }}
          d="m 12.624785,13.248365 -3.5574599,1.97916 -0.72653,-0.35074 z m 0.107,0.43288 0.37119,1.73073 -2.18459,0.53561 -1.4011499,-0.49436 z m -3.9814899,1.97595 -0.75814,-0.41 -2.40806,1.66799 -1.17364,1.50707 -0.62662,1.56259 0.0464,3.70195 1.3284,-1.72153 -0.0407,-2.59376 0.48843,-0.5005 c 0,0 3.09777,-3.19057 3.1437,-3.214 z m 0.2409,0.10873 c 0.002,0.0525 -3.32987,3.54733 -3.32987,3.54733 l -0.10067,3.10396 1.15426,-1.97782 2.22547,-0.94804 1.5657499,-2.88481 z"
        ></path>
        <path
          className="biceps"
          style={{ opacity: "1", fill: "black" }}
          d="m 27.621665,30.814715 -0.33838,1.70499 -1.81932,-2.54418 -0.6629,-1.26895 z m -2.85271,-2.6096 c -0.0259,-0.0144 -0.0536,-0.0254 -0.0824,-0.0324 l -1.48333,-4.95503 1.00456,-2.08428 1.65511,1.74532 2.23034,6.67667 0.0415,0.93739 c -1.06528,-0.84215 -2.18962,-1.60679 -3.36434,-2.28803 z m 1.6945,-5.75654 1.64893,6.43421 -0.36469,-4.92266 z"
        ></path>
        <path className="forearms" style={{ opacity: "1", fill: "black" }} d="m 26.955425,32.969125 1.30083,10.28927 -1.10778,0.01 -1.89387,-7.99609 0.19174,-4.53719 z m 1.21978,-1.94971 -0.58729,2.58635 1.11876,9.15614 0.55849,-0.21663 0.2304,-6.77018 z"></path>
        <path
          className="biceps"
          style={{ opacity: "1", fill: "black" }}
          d="m 4.0746451,30.814715 0.33838,1.70499 1.81931,-2.54418 0.66289,-1.26895 z m 2.8527,-2.6096 c 0.0259,-0.0144 0.0536,-0.0254 0.0824,-0.0324 l 1.48332,-4.95503 -1.00455,-2.08428 -1.65509,1.74532 -2.23034,6.67667 -0.0415,0.93739 c 1.06528,-0.84215 2.18961,-1.60679 3.36433,-2.28803 z m -1.6945,-5.75654 -1.64891,6.43421 0.36468,-4.92266 z"
        ></path>
        <path className="forearms" style={{ opacity: "1", fill: "black" }} d="m 4.5752651,32.969125 -1.30083,10.28927 1.10778,0.01 1.89387,-7.99609 -0.19174,-4.53719 z m -1.21978,-1.94971 0.58728,2.58635 -1.11875,9.15614 -0.55849,-0.21663 -0.2304,-6.77018 z"></path>
        <path className="chest" style={{ opacity: "1", fill: "black" }} d="m 20.337455,17.085495 1.72942,3.09103 1.89346,0.94785 -1.15295,0.90662 -0.90604,2.63773 -2.09968,0.86537 -3.34524,-1.655 0.83425,-6.50527 z"></path>
        <path className="chest" style={{ opacity: "1", fill: "black" }} d="m 11.351215,17.085495 -1.7294199,3.09103 -1.89346,0.94785 1.15295,0.90662 0.90586,2.63773 2.0996699,0.86537 3.34636,-1.655 -0.83462,-6.50527 z"></path>
        <path className="abdominals" style={{ opacity: "1", fill: "black" }} d="m 19.641935,34.707615 1.81341,-1.36479 0.15748,1.83347 1.28642,2.37338 -1.98044,2.73652 -1.03109,0.16554 -0.37026,-3.88816 z"></path>
        <path
          className="abdominals"
          style={{ opacity: "1", fill: "black" }}
          d="m 19.288925,26.151995 -3.11202,-1.40604 0.0937,2.27965 2.80119,1.43603 z m 1.93471,1.66849 -1.29355,0.7212 0.14997,-1.70898 z m -1.05303,-1.63718 2.47968,-1.03241 -0.9336,2.52093 z m 1.53164,1.73729 -1.69005,1.03372 -0.28871,2.0678 1.64975,-1.07533 z m -2.91143,1.10421 -0.0622,1.62387 -2.30308,-0.49961 -0.12448,-2.21722 z m -0.1556,2.4045 0.0311,1.99844 -2.20953,0.59391 -0.0311,-3.1227 z m 2.65459,-0.98535 -1.48383,1.03372 -0.20622,2.10905 1.64862,-1.32355 z"
        ></path>
        <path className="abdominals" style={{ opacity: "1", fill: "black" }} d="m 12.045985,34.707615 -1.81341,-1.36479 -0.15748,1.83347 -1.2856799,2.37432 1.9804499,2.73595 1.03109,0.16554 0.37119,-3.88721 z"></path>
        <path className="abdominals" style={{ opacity: "1", fill: "black" }} d="m 15.636055,44.919735 -0.60647,-5.91209 -0.015,-3.84879 -2.18479,-1.07533 -0.24746,7.03017 z m 0.41581,-5.7e-4 0.60628,-5.91209 0.0154,-3.84915 2.18404,-1.07515 0.24746,7.03017 z"></path>
        <path
          className="abdominals"
          style={{ opacity: "1", fill: "black" }}
          d="m 12.399365,26.152365 3.11202,-1.40603 -0.0937,2.27965 -2.80138,1.4364 z m -1.93508,1.6685 1.29355,0.72139 -0.14997,-1.70899 z m 1.05303,-1.637 -2.4793099,-1.03259 0.93361,2.52148 z m -1.5316399,1.73729 1.6900499,1.03372 0.28871,2.06743 -1.64881,-1.07515 z m 2.9114199,1.10421 0.0623,1.62387 2.30327,-0.49961 0.12448,-2.21703 z m 0.15561,2.40432 -0.0309,1.99844 2.20973,0.59353 0.0311,-3.1227 z m -2.6546,-0.98516 1.48384,1.0339 0.20622,2.10905 -1.64975,-1.32355 z"
        ></path>
        <path className="quadriceps" style={{ opacity: "1", fill: "black" }} d="m 23.419015,50.399125 -0.15504,4.75091 -2.40263,6.60949 0.7362,1.90021 2.36401,-8.34435 z m -0.58154,-11.60825 -0.15485,4.00722 1.31793,7.93154 0.61977,-6.40308 z m -0.38731,5.12268 -2.75152,6.07258 -0.62015,4.87425 1.16232,6.85771 2.51886,-6.98144 0.15504,-7.18764 z"></path>
        <path className="abductors" style={{ opacity: "1", fill: "black" }} d="m 22.063225,39.369605 v 4.21363 l -2.94574,5.82511 -1.86027,5.78349 0.19365,-4.0072 z m -3.24944,13.42596 -0.0649,0.15467 -1.21294,2.90207 0.78325,7.18803 1.23619,-0.66122 -1.0714,-6.69272 z"></path>
        <path className="calves" style={{ opacity: "1", fill: "black" }} d="m 17.255895,87.868445 0.1243,3.45228 0.28983,1.20638 h 0.87136 l 0.24897,-0.83181 0.29058,-0.0416 -0.0624,0.83181 1.09914,-0.33332 0.29058,-0.16629 1.24444,-0.27033 0.0416,-0.97748 -1.20319,-2.03743 -0.82974,-1.0399 -2.03294,-0.83181 z"></path>
        <path className="calves" style={{ opacity: "1", fill: "black" }} d="m 18.251375,70.441125 0.29058,0.91486 0.6224,3.8681 0.0829,5.15733 -0.87136,5.03304 0.0412,-6.44714 -0.91242,-2.57848 -0.12561,-2.82837 z m 1.9915,2.32915 -0.20753,7.73637 -1.65949,6.23904 1.80478,-0.853 3.00816,-10.83583 -1.03727,-6.82095 z"></path>
        <path
          className="knee-left"
          style={{ opacity: "1", fill: "black" }}
          d="m 21.404635,64.784375 0.1243,1.12295 -0.87118,1.08171 -0.29058,1.70599 -0.58116,0.24933 -0.49774,-2.57866 -0.33182,-0.91486 0.29058,-0.58247 z m -3.85853,0.0832 0.6224,1.74685 1.3273,2.57867 -0.33182,2.37095 -0.95423,-2.66209 -0.78738,-1.49734 z m 4.97811,-2.37039 -0.95423,5.11609 0.62241,-0.33295 0.49773,1.66381 z"
        ></path>
        <path className="quadriceps" style={{ opacity: "1", fill: "black" }} d="m 8.2694651,50.399125 0.15504,4.75053 2.4026299,6.60968 -0.73638,1.90021 -2.3640099,-8.34435 z m 0.58117,-11.60768 0.15503,4.00684 -1.31754,7.93154 -0.61978,-6.40308 z m 0.38769,5.1223 2.7515099,6.07239 0.61997,4.87425 -1.16232,6.85771 -2.5190499,-6.98163 -0.15504,-7.18801 z"></path>
        <path
          className="adductors"
          style={{ opacity: "1", fill: "black" }}
          d="m 14.404465,45.040075 0.0221,-0.0277 -0.14866,-0.37945 -3.10172,-3.40449 -0.23283,-0.0825 2.05918,5.32009 z m -1.17263,2.01833 1.27705,3.29948 0.42631,-4.04862 -0.25196,-0.64303 z m 4.05219,-2.01795 -0.0221,-0.0281 0.14867,-0.37926 3.10171,-3.40449 0.23246,-0.0825 -2.05843,5.3199 z m 1.17263,2.01795 -1.27706,3.29948 -0.42631,-4.04843 0.25197,-0.64303 z"
        ></path>
        <path className="abductors" style={{ opacity: "1", fill: "black" }} d="m 9.6258251,39.369415 v 4.21363 l 2.9451699,5.8253 1.86028,5.78349 -0.19366,-4.0072 z m 3.2488699,13.42559 0.0647,0.15485 1.21294,2.90207 -0.78307,7.18803 -1.23618,-0.66102 1.0714,-6.69273 z"></path>
        <path className="calves" style={{ opacity: "1", fill: "black" }} d="m 14.433335,87.868265 -0.12448,3.45228 -0.29058,1.20637 h -0.87118 l -0.24877,-0.83181 -0.29059,-0.0416 0.0623,0.83181 -1.09934,-0.33333 -0.29058,-0.16629 -1.2448,-0.27033 -0.0412,-0.97747 1.2031899,-2.03781 0.82975,-1.04009 2.03294,-0.83181 z"></path>
        <path className="calves" style={{ opacity: "1", fill: "black" }} d="m 13.437675,70.440945 -0.29058,0.91486 -0.62241,3.86828 -0.0829,5.15733 0.87174,5.03304 -0.0418,-6.44714 0.91298,-2.57848 0.1243,-2.82837 z m -1.99151,2.32914 0.20735,7.73637 1.65968,6.23904 -1.80497,-0.85299 -3.0079799,-10.83584 1.03728,-6.82095 z"></path>
        <path
          className="knee-right"
          style={{ opacity: "1", fill: "black" }}
          d="m 10.284405,64.784375 -0.12448,1.12295 0.87118,1.08171 0.29058,1.70599 0.58116,0.24933 0.49774,-2.57866 0.33182,-0.91486 -0.29058,-0.58247 z m 3.85854,0.0832 -0.62241,1.74685 -1.32767,2.57867 0.33182,2.37095 0.95423,-2.66209 0.78832,-1.4964 z m -4.9786799,-2.37058 0.9542299,5.11609 -0.6223999,-0.33313 -0.49793,1.6638 z"
        ></path>
        <path className="elbow-right" style={{ opacity: "1", fill: "black" }} d="m 3.2054751,27.370125 0.005,3.09419 -0.57959,1.91184 -0.54539,-2.41185 z"></path>
        <path
          className="hand-right"
          style={{ opacity: "1", fill: "black" }}
          d="m 4.3904451,43.563145 -1.5198,0.0506 -0.76631,-0.67112 -1.21261996,2.15767 -0.86245,3.32873 0.49386,0.22113 0.59814996,-2.20238 0.50016,0.25356 -0.35639,2.49422 0.62382,0.24345 0.41402,-2.49194 0.55839,0.17851 -0.2262,2.76603 0.76938,0.32268 0.25788,-2.86764 0.4578,-0.0181 0.16611,2.65239 0.65997,0.2633 0.0712,-4.56643 0.34158,-0.19428 1.35316,1.68367 0.32832,-0.34354 -0.72644,-2.0551 z"
        ></path>
        <path className="elbow-left" style={{ opacity: "1", fill: "black" }} d="m 28.325215,27.370125 -0.005,3.09419 0.57959,1.91184 0.54538,-2.41185 z"></path>
        <path
          className="hands-left"
          style={{ opacity: "1", fill: "black" }}
          d="m 27.140245,43.563145 1.5198,0.0506 0.76631,-0.67111 1.21262,2.15766 0.86245,3.32873 -0.49386,0.22113 -0.59815,-2.20238 -0.50016,0.25356 0.35639,2.49422 -0.62382,0.24345 -0.41402,-2.49194 -0.55839,0.17851 0.2262,2.76603 -0.76938,0.32268 -0.25788,-2.86764 -0.4578,-0.0181 -0.16611,2.6524 -0.65997,0.26329 -0.0712,-4.56643 -0.34158,-0.19428 -1.35316,1.68368 -0.32832,-0.34355 0.72644,-2.0551 z"
        ></path>
        <path
          className="triceps"
          style={{ opacity: "1", fill: "black" }}
          d="m 43.185645,27.069445 0.4297,-1.4164 1.30458,-1.68577 -1.39393,-2.96155 -2.28367,0.92162 -1.83567,1.7467 -0.53524,1.78673 0.27068,4.30806 z m -2.46869,15.35539 -1.5182,0.0863 -0.78184,-0.65295 -1.16168,2.1855 -0.78414,3.34805 0.49892,0.20949 0.54632,-2.2158 0.50597,0.24175 -0.29779,2.5019 0.62936,0.22875 0.35546,-2.50096 0.56242,0.16536 -0.16126,2.77057 0.77674,0.30455 0.19056,-2.87291 0.45724,-0.0289 0.22827,2.64778 0.66597,0.24774 -0.0359,-4.56685 0.33693,-0.20224 1.39227,1.65147 0.32017,-0.35115 -0.77444,-2.03749 z m -0.97726,-0.17765 -1.43509,-0.746 -0.30622,-7.00985 c 0,0 0.64359,-2.77938 0.63694,-3.06274 l 0.6093,-1.21924 3.62552,-2.56583 -0.68276,1.9919 0.41561,4.74788 -1.80402,7.69727 z"
        ></path>
        <path
          className="hamstrings"
          style={{ opacity: "1", fill: "black" }}
          d="m 51.176145,64.073985 -1.20605,3.01461 0.70738,0.26558 0.89754,3.51771 -0.55801,-4.01191 z m -5.08496,-3.15003 0.63355,1.8609 0.16813,2.03261 0.61314,1.93117 -0.90585,-0.0851 -0.28534,2.15982 z m 4.3014,6.58834 1.27664,4.99697 -0.28984,3.02284 -0.67869,10.06546 -1.66325,0.63506 -3.50399,-11.96959 1.24985,-7.17525 z m 0.54053,20.8287 0.85194,1.3581 0.37189,0.79238 -0.15588,1.21774 -0.76984,0.74446 -1.51185,0.12543 -1.1299,-0.29192 -0.24225,-0.95894 0.80765,-1.30405 -0.22562,-0.85987 0.29679,-0.84153 -0.0194,-1.81524 1.53568,-0.54817 z m -1.19598,0.4675 0.15943,1.25776 -0.6023,0.97431 m -0.54436,0.29544 1.06474,0.40084 1.55326,-0.65137 m -4.19331,-39.53466 4.55099,-2.03879 0.63802,0.23079 0.0353,1.80672 0.075,4.64669 -1.97837,6.04282 0.47612,1.41403 -1.42812,3.29446 -1.76611,-0.30111 -0.50079,-2.11605 -0.1695,-1.75674 -2.42102,-8.15763 -0.34279,-3.64687 z"
        ></path>
        <path className="glutes" style={{ opacity: "1", fill: "black" }} d="m 44.742845,39.689035 5.48374,1.86457 2.27386,1.3378 2.74195,-1.74412 4.51804,-1.28077 0.90009,2.29721 0.675,3.4346 -0.81272,5.02838 -2.82636,0.16819 -4.11256,-1.67581 -1.00814,0.39118 -0.95849,-0.39888 -4.44053,1.94411 -2.77023,-0.51478 -0.95181,-6.15325 0.36754,-2.7864 z"></path>
        <path className="lowerback" style={{ opacity: "1", fill: "black" }} d="m 51.818445,37.309575 0.14418,2.97292 1.15984,-0.0241 0.048,-2.96488 2.80867,-0.81981 2.34029,-0.7541 1.34121,3.73319 -4.77886,1.36455 -2.33301,1.2158 -2.37536,-1.2333 -5.45663,-1.37716 1.51961,-3.95743 z"></path>
        <path className="middleback" style={{ opacity: "1", fill: "black" }} d="m 51.733705,14.788555 0.53876,25.33066 0.48967,-0.0297 0.65658,-25.3387 -0.28147,-0.84188 -1.25059,-4.9e-4 z"></path>
        <path className="head" style={{ opacity: "1", fill: "black" }} d="m 48.157455,6.3585449 0.44208,-0.14964 0.16111,0.16427 1.48163,4.0475101 2.32401,1.45118 2.39971,-1.52387 0.97577,-3.6896901 0.52752,-0.55908 0.23367,0.0981 0.24198,-3.34467 -2.03129,-2.31103004 -2.84509,-0.51629 -2.20422,0.52915 -1.93631,2.63077004 z"></path>
        <path
          className="neck"
          style={{ opacity: "1", fill: "black" }}
          d="m 52.369695,12.105075 -2.35767,-1.55045 -1.47119,-3.9514301 -0.60741,0.0403 0.27409,1.82447 0.97635,0.33932 0.7613,2.2157201 0.33017,1.06849 0.0895,2.14894 1.16448,0.008 0.10563,-0.70833 0.54716,-0.0606 z m 1.01793,1.47595 0.23768,0.64982 1.38107,-0.004 0.01,-2.38784 0.25971,-0.79061 0.57215,-2.1698001 0.76359,-0.41018 0.25158,-1.78416 -0.62859,0.0193 -1.08488,3.8998101 -2.39725,1.46684 0.2768,1.48507 z"
        ></path>
        <path
          className="triceps"
          style={{ opacity: "1", fill: "black" }}
          d="m 61.657445,27.250625 -0.32785,-1.05121 -1.27383,-2.05489 1.38708,-2.96476 2.28579,0.91634 1.83971,1.74245 0.53937,1.78549 -0.26073,4.30868 z m 2.64394,15.3417 1.51839,0.0828 0.78033,-0.65476 1.16673,2.18281 0.79187,3.34623 -0.49843,0.21064 -0.55144,-2.21453 -0.50541,0.24292 0.30356,2.5012 -0.62882,0.23021 -0.36124,-2.50014 -0.56203,0.16666 0.16765,2.77019 -0.77603,0.30634 -0.19719,-2.87245 -0.45732,-0.0278 -0.22215,2.64829 -0.66539,0.24928 0.0254,-4.56692 -0.3374,-0.20146 -1.38845,1.65469 -0.32098,-0.35041 0.76973,-2.03928 z m 0.97685,-0.1799 1.43335,-0.74932 0.29002,-7.01054 c 0,0 -0.65,-2.77789 -0.64401,-3.06126 l -0.61212,-1.21783 -3.98124,-2.57566 1.0222,1.93525 -0.38967,4.82212 1.8218,7.69308 z"
        ></path>
        <path
          className="hamstrings"
          style={{ opacity: "1", fill: "black" }}
          d="m 54.019305,64.073985 1.20605,3.01461 -0.70737,0.26558 -0.89755,3.51771 0.55802,-4.01191 z m 5.08496,-3.15003 -0.63355,1.8609 -0.16813,2.03261 -0.61313,1.93117 0.90584,-0.0851 0.28534,2.15982 z m -4.3014,6.58834 -1.27664,4.99697 0.28984,3.02284 0.67869,10.06546 1.66325,0.63506 3.504,-11.96959 -1.24986,-7.17525 z m -0.54053,20.8287 -0.85194,1.3581 -0.37189,0.79238 0.15589,1.21774 0.76983,0.74446 1.51186,0.12543 1.12989,-0.29192 0.24225,-0.95894 -0.80765,-1.30405 0.22563,-0.85987 -0.29679,-0.84153 0.0194,-1.81524 -1.53568,-0.54817 z m 1.19598,0.4675 -0.15943,1.25776 0.6023,0.97431 m 0.54436,0.29544 -1.06474,0.40084 -1.55326,-0.65137 m 3.56525,-39.90247 -3.97962,-1.70224 -0.56389,0.27131 -0.0528,1.79746 -0.075,4.64669 1.97837,6.04282 -0.47612,1.41403 1.42813,3.29446 1.7661,-0.30111 0.50079,-2.11605 0.1695,-1.75674 2.42102,-8.15763 0.009,-3.68308 z"
        ></path>
        <path className="lats" style={{ opacity: "1", fill: "black" }} d="m 62.863315,16.685695 1.57473,1.56518 0.81404,2.06904 0.0384,2.52859 -1.48921,-1.23926 -2.76223,-1.15539 -1.84691,3.4342 -1.13679,5.49715 -0.0767,5.8593 -4.07066,1.10938 0.10355,-7.94098 1.94107,-4.90021 5.04395,-8.19335 z"></path>
        <path className="traps" style={{ opacity: "1", fill: "black" }} d="m 55.439085,14.728535 -0.063,-2.62463 0.71441,1.15181 4.37994,1.49796 -4.97857,8.36746 -1.83043,5.08189 0.21949,-13.55362 z"></path>
        <path className="lats" style={{ opacity: "1", fill: "black" }} d="m 42.200945,16.586495 -1.57473,1.56517 -0.81404,2.06905 -0.38603,2.52859 1.83679,-1.23927 2.76223,-1.15538 1.84691,3.4342 1.13679,5.49715 0.0767,5.8593 4.07066,1.10938 -0.10355,-7.94098 -1.94107,-4.90022 -5.04395,-8.19334 z"></path>
        <path className="traps" style={{ opacity: "1", fill: "black" }} d="m 49.625175,14.629325 0.063,-2.62462 -0.71441,1.15181 -4.37994,1.49796 4.97857,8.36746 1.83043,5.08188 -0.21949,-13.55362 z"></path>
      </svg>
    );
    React.Children.forEach(svg.props.children, (child) => {
      muscles.forEach((m) => {
        if (m.muscle.replace(/\s+/g, "") === child.props.className) {
          if (m.count === 0) {
            child.props.style.fill = "#800000";
          } else if (m.count <= 4 || m.count < 8) {
            child.props.style.fill = "#ff851b";
          } else if (m.count <= 8 || m.count <= 10) {
            child.props.style.fill = "#d9e778";
          } else {
            child.props.style.fill = "#2ecc40";
          }
        }
      });
    });
    return svg;
  };
  useEffect(() => {
    const fetchAndUpdateCollection = async () => {
      try {
        const response = await axios.get(`assets/exercises.json`);
        const data = response.data.sort((a, b) => a.name - b.name);
        let updatedList = updateCollection(data, currentForce, currentLevel, currentMechanic, currentEquipment, currentMuscles, currentCategory);
        setCollection(updatedList);
      } catch (error) {
        console.error("Error fetching songs JSON:", error);
      }
    };
    fetchAndUpdateCollection();
  }, [currentForce, currentLevel, currentMechanic, currentEquipment, currentMuscles, currentCategory, updateCollection]);

  function saveUser() {
    const name = document.getElementById("name").value;
    const age = document.getElementById("age").value;
    const weight = document.getElementById("weight").value;
    const set = document.getElementById("set").value;
    const rep = document.getElementById("rep").value;
    if (!name || !age || !weight) {
      alert("All fields are required!");
      return;
    }

    if (isNaN(age) || age <= 0) {
      alert("Please enter a valid age.");
      return;
    }

    if (isNaN(weight) || weight <= 0) {
      alert("Please enter a valid weight.");
      return;
    }
    const workoutProgram = new WorkoutProgram(name, Number(age), Number(weight), Number(set), Number(rep));

    localStorage.setItem("workoutProgram", JSON.stringify(workoutProgram));
    setUserCollection(workoutProgram);
    setShow(false);
    alert("Workout program saved successfully!");
  }
  function OpenTab(id) {
    const collapseElement = document.getElementById(`${id}-tab-pane`);
    const buttonElement = document.getElementById(`${id}-tab`);
    const myTab = document.getElementById(`myTab`);
    const myTabContent = document.getElementById(`myTabContent`);
    if (collapseElement.classList.contains("show")) {
      collapseElement.classList.remove("show");
      collapseElement.classList.remove("active");
      buttonElement.classList.remove("active");
    } else {
      const childrenTabs = myTab.querySelectorAll("*");
      const childrenTabContent = myTabContent.querySelectorAll("*");
      childrenTabs.forEach((child) => {
        child.classList.remove("show");
        child.classList.remove("active");
      });
      childrenTabContent.forEach((child) => {
        child.classList.remove("show");
        child.classList.remove("active");
      });
      collapseElement.classList.add("show");
      collapseElement.classList.add("active");
      buttonElement.classList.add("active");
    }
  }
  // const UserAuthorization = async () => {
  //   let Uri = true ? "https://sandbox-api.dexcom.com/" : "https://api.dexcom.com/";
  //   const authUrl = `${Uri}v2/oauth2/login?client_id=cHujfOeO48SOz7OsSAIPdIOZascyY243&redirect_uri=https://royclaudio.com/Exercise&response_type=code&scope=offline_access`;
  //   window.location.replace(authUrl);
  // };
  // const DexcomRequests = async (access_token) => {
  //   try {
  //     const response = await axios.post(`/GetReading/${access_token}`);
  //     let responseData = response.data;
  //     let alertMessage = "Dexcom Response Data:\n";
  //     alertMessage += `${responseData.unit ? responseData.unit : "N/A"}: ${responseData.rateUnit ? responseData.rateUnit : "N/A"}\n`;
  //     for (const [key, value] of Object.entries(responseData.egvs)) {
  //       alertMessage += `${key}: ${value ? value : "N/A"}\n`;
  //     }
  //     alert(alertMessage);
  //   } catch (error) {
  //     console.error("Error fetching JSON:", error);
  //   }
  // };
  function accordion(event) {
    let elem = event.currentTarget;
    let child = elem.nextElementSibling;
    if (elem.classList.contains("active")) {
      elem.classList.remove("active");
      child.classList.remove("active");
      // child.style.transition = "transform 1ms ease-in-out, opacity 1s ease-in-out";
      // requestAnimationFrame(() => {
      //   child.style.transform = "translateY(-100%)";
      //   child.style.opacity = "0";
      // });
    } else {
      // child.style.transition = "transform 1s ease-in-out, opacity 1s ease-in-out";
      // requestAnimationFrame(() => {
      //   child.style.transform = "translateY(0)";
      //   child.style.opacity = "1";
      // });
      elem.classList.add("active");
      child.classList.add("active");
    }
  }
  const myRef = React.useRef(null);
  function scheduleFilter(idx) {
    collectionUser.split[idx].muscles
      .map((b) => b)
      .forEach((muscle) => {
        handleCollectionChange("muscles", muscle);
      });
    collectionUser.split[idx].muscles.map((b) => handleCollectionChange("muscle", b));
  }
  const AddWorkout = (exercise, sets, split, iden) => {
    let letadd = true;
    if (iden) {
      const elem = document.getElementById(iden);
      if (elem.classList.contains("plus")) {
        elem.classList.remove("plus");
        elem.classList.remove("icon");
        elem.classList.add("icon");
        elem.classList.add("check");
      } else {
        elem.classList.remove("check");
        elem.classList.remove("icon");
        elem.classList.add("icon");
        elem.classList.add("plus");
        letadd = false;
        DeleteWorkout(exercise);
      }
    }
    if (letadd) {
      setShow1(show1 ? false : false);
      let msec = Date.now();
      let startDate = new Date(msec).toISOString();
      split.exercises.push({
        move: exercise,
        sets: sets,
        create_date: startDate,
        iscompleted: false,
        modified_date: null,
      });
      handleCollectionChange("muscles", "");
      setUserCollection(collectionUser);
      localStorage.setItem("workoutProgram", JSON.stringify(collectionUser));
    }
  };
  const AddMuscle = (split, muscle) => {
    split.muscles.push(muscle);
    setUserCollection(collectionUser);
    localStorage.setItem("workoutProgram", JSON.stringify(collectionUser));
    handleCollectionChange("muscles", "");
  };
  const RemoveMuscle = (split, muscle) => {
    split.muscles = split.muscles.filter((a) => a !== muscle);
    setUserCollection(collectionUser);
    localStorage.setItem("workoutProgram", JSON.stringify(collectionUser));
    handleCollectionChange("muscles", "");
  };
  const CompleteWorkout = (exercise) => {
    let msec = Date.now();
    let startDate = new Date(msec).toISOString();
    exercise.iscompleted = exercise.iscompleted ? false : true;
    exercise.modified_date = startDate;
    setShow1(false);
    setUserCollection(collectionUser);
    localStorage.setItem("workoutProgram", JSON.stringify(collectionUser));
  };
  const DeleteWorkout = (exercise) => {
    setShow1(false);
    collectionUser.split.map((a) => {
      a.exercises = a.exercises.filter((b) => b.move.name !== exercise.name);
      return "";
    });
    setUserCollection(collectionUser);
    handleCollectionChange("muscles", "");
    localStorage.setItem("workoutProgram", JSON.stringify(collectionUser));
  };
  const DeleteAllWorkout = () => {
    alert(`All Exercises Deleted`);
    setShow1(false);
    collectionUser.split.forEach((split) => {
      split.exercises = [];
    });
    handleCollectionChange("muscles", "");
    setUserCollection(collectionUser);
    localStorage.setItem("workoutProgram", JSON.stringify(collectionUser));
  };
  const NewWeek = () => {
    let msec = Date.now();
    let date = new Date(msec).toISOString();
    alert(`New Week Started`);
    setShow1(false);
    collectionUser.schedule_history.push({
      week: collectionUser.schedule_history.length + 1,
      completed: collectionUser.split.map((a) => a.exercises.filter(b => b.iscompleted === true).length).reduce((a, b) => a + b),
      notcompleted: collectionUser.split.map((a) => a.exercises.filter(b => b.iscompleted === false).length).reduce((a, b) => a + b),
      date: date,
      split: collectionUser.split,
    });
    collectionUser.split.forEach((split) => {
      split.exercises.forEach((exercise) => {
        exercise.iscompleted = false;
        exercise.modified_date = null;
        exercise.create_date = date;
      });
    });
    handleCollectionChange("muscles", "");
    setUserCollection(collectionUser);
    localStorage.setItem("workoutProgram", JSON.stringify(collectionUser));
  };
  const ShowWorkouts = (option) => {
    if (option === 0) {
      handleCollectionChange("muscles", "");
    } else {
      setCollection(collectionUser.split.flatMap((o) => o.exercises).map((a) => a.move));
    }
  };
  const OptimalWorkout = () => {
    let maxSetsPerMuscle = 12;
    let setsPerExercise = 4;
    let muscles = collectionMuscles;
    let weights = [];
    let values = [];
    let optimizedExercises = [];

    collectionMain.forEach((exercise) => {
      weights.push(
        muscles.map((muscle) => {
          const isPrimary = exercise.primaryMuscles.includes(muscle);
          const isSecondary = exercise.secondaryMuscles.includes(muscle);
          return { muscle: muscle, weight: setsPerExercise * (isPrimary ? 1 : isSecondary ? 0.5 : 0) };
        })
      );
      values.push(
        muscles.map((muscle) => {
          const isPrimary = exercise.primaryMuscles.includes(muscle);
          const isSecondary = exercise.secondaryMuscles.includes(muscle);
          return { muscle: muscle, value: setsPerExercise * (isPrimary ? 1 : isSecondary ? 0.5 : 0) };
        })
      );
    });

    let dp = Array(muscles.length)
      .fill(0)
      .map(() => Array(maxSetsPerMuscle + 1).fill(Infinity));
    let itemCount = Array(muscles.length)
      .fill(0)
      .map(() => Array(maxSetsPerMuscle + 1).fill(Infinity));

    for (let k = 0; k < muscles.length; k++) {
      dp[k][0] = 0;
      itemCount[k][0] = 0;
    }

    for (let i = 0; i < weights.length; i++) {
      for (let k = 0; k < muscles.length; k++) {
        for (let j = maxSetsPerMuscle; j >= weights[i][k].weight; j--) {
          const newValue = dp[k][j - weights[i][k].weight] + values[i][k].value;
          const newItemCount = itemCount[k][j - weights[i][k].weight] + 1;
          if (!isNaN(newValue) && newItemCount < itemCount[k][j]) {
            dp[k][j] = newValue;
            itemCount[k][j] = newItemCount;
          }
        }
      }
    }

    const muscleWeights = Array(muscles.length).fill(0);
    for (let muscleIndex = 0; muscleIndex < muscles.length; muscleIndex++) {
      let remainingSets = maxSetsPerMuscle;
      for (let i = weights.length - 1; i >= 0 && remainingSets > 0; i--) {
        const weight = weights[i][muscleIndex].weight;
        const value = values[i][muscleIndex].value;
        if (muscleWeights[muscleIndex] + weight <= maxSetsPerMuscle && dp[muscleIndex][remainingSets] === dp[muscleIndex][remainingSets - weight] + value && itemCount[muscleIndex][remainingSets] === itemCount[muscleIndex][remainingSets - weight] + 1) {
          if (!optimizedExercises.includes(collectionMain[i])) {
            optimizedExercises.push(collectionMain[i]);
            muscleWeights[muscleIndex] += weight;
            remainingSets -= weight;
          }
        }
      }
    }
    setCollection(optimizedExercises);
  };
  async function searchFunction(query) {
    let sq = query.toUpperCase();
    const response = await axios.get(`assets/exercises.json`);
    const data = response.data.sort((a, b) => a.name - b.name);
    setCollection(data.filter((a) => a.name.toUpperCase().includes(sq)));
  }
  return (
    <div className="ui container">
      <div className="off-screen">
        <Modal id="modal" size="lg" show={show} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Sign Up</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShow(false);
              }}
            ></button>
          </Modal.Header>
          <Modal.Body>
            <form className="row gy-2 gx-3 align-items-center">
              <div className="col-auto">
                <label className="visually-hidden" htmlFor="name">
                  Name
                </label>
                <div className="input-group">
                  <div className="input-group-text">Name</div>
                  <input type="text" style={{ fontSize: "16px" }} className="form-control" id="name" placeholder="Name" value={collectionUser.name} />
                </div>{" "}
              </div>
              <div className="col-auto">
                <label className="visually-hidden" htmlFor="age">
                  Age
                </label>
                <div className="input-group">
                  <div className="input-group-text">Age</div>
                  <input type="number" style={{ fontSize: "16px" }} className="form-control" id="age" placeholder="00" value={collectionUser.age} />
                </div>
              </div>
              <div className="col-auto">
                <label className="visually-hidden" htmlFor="weight">
                  Weight
                </label>
                <div className="input-group">
                  <div className="input-group-text">Weight</div>
                  <input type="number" style={{ fontSize: "16px" }} className="form-control" id="weight" placeholder="000lbs" value={collectionUser.weight} />
                </div>
              </div>
              <div className="w-100">Muscle</div>
              <div className="col-auto">
                <label className="visually-hidden" htmlFor="set">
                  Set Frequency
                </label>
                <div className="input-group">
                  <div className="input-group-text">Set Frequency</div>
                  <input type="number" style={{ fontSize: "16px" }} className="form-control" id="set" placeholder="0" value={collectionUser.frequency_sets} />
                </div>
              </div>
              <div className="col-auto">
                <label className="visually-hidden" htmlFor="rep">
                  Rep Frequency
                </label>
                <div className="input-group">
                  <div className="input-group-text">Rep Frequency</div>
                  <input type="number" style={{ fontSize: "16px" }} className="form-control" id="rep" placeholder="0" value={collectionUser.frequency_reps} />
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="ui basic button"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="ui basic button"
              onClick={() => {
                saveUser();
              }}
            >
              {collectionUser.name ? "Save" : "Register"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal id="Excercise" size="lg" show={show1} centered>
          <Modal.Header>
            <div className="content">
              <div className="header info-title">{selectedObj.name}</div>
              <div className="meta">
                <a target="_blank" rel="noreferrer" href={`http://www.google.co.in/search?q=${selectedObj.name} exercise`}>
                  Google Exercise
                </a>
              </div>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShow1(false);
              }}
            ></button>
          </Modal.Header>
          <Modal.Body>
            <div className="ui horizontal list">
              {(collectionUser.split && collectionUser.split.flatMap((o) => o.exercises) && collectionUser.split.flatMap((o) => o.exercises).filter((a) => a.move.name === selectedObj.name).length > 0) ? (
                <div className="item">
                  <div
                    className="ui button"
                    onClick={() => {
                      CompleteWorkout(collectionUser.split.flatMap((o) => o.exercises).find((a) => a.move.name === selectedObj.name));
                    }}
                  >
                    Mark As Complete
                  </div>
                </div>
              ) : <div className="item">
                <div className="ui compact menu mx-3">
                  <div className="ui simple dropdown item">
                    <i className="add icon"></i>
                    Add Exercise
                    <div className="menu m-0 p-0">
                      {Array(12)
                        .fill(12)
                        .map((_, index) => (
                          <div key={index} className="ui simple dropdown item">
                            {index + 1} Set
                            <div className="menu">
                              {collectionUser.split &&
                                collectionUser.split.map((split, idx) => (
                                  <div key={idx} className="item" onClick={() => AddWorkout(selectedObj, index + 1, split)}>
                                    {split.day}
                                  </div>
                                ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>}
              {collectionUser.split && collectionUser.split.flatMap((o) => o.exercises) && collectionUser.split.filter((a) => a.exercises.some(a => a.move.name === selectedObj.name)).length > 0 && (
                <div class="item">
                  {/* {console.log(collectionUser.split.find((a) => a.exercises.some(a => a.move.name === selectedObj.name)))} */}
                  <div class="content">
                    <div class="ui button">Edit</div>
                  </div>
                </div>
              )}
            </div>
            <div className="ui divider"></div>
            <div className="ui horizontal list">
              <div className="item">
                <div className="content">
                  <div className="ui sub header">{selectedObj.category}</div>
                  Category
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <div className="ui sub header">{selectedObj.force}</div>
                  Force
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <div className="ui sub header">{selectedObj.level}</div>
                  Level
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <div className="ui sub header">{selectedObj.mechanic ? selectedObj.mechanic : "None"}</div>
                  Mechanic
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <div className="ui sub header">{selectedObj.equipment}</div>
                  Equipment
                </div>
              </div>
            </div>
            <div className="ui divider"></div>
            <div className="ui horizontal list">
              <div className="item">
                <div className="content">
                  <div className="ui sub header">{selectedObj.primaryMuscles}</div>
                  Primary Muscle
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <div className="ui sub header">{selectedObj.secondaryMuscles && selectedObj.secondaryMuscles.join(", ")}</div>
                  Secondary Muscles
                </div>
              </div>
            </div>
            <div className="ui divider"></div>
            <div className="ui tree accordion">
              {selectedObj.instructions &&
                selectedObj.instructions.map((a, idx) => (
                  <div key={idx}>
                    <div className="active title" onClick={accordion} ref={myRef}>
                      {a.split(". ")[0]}
                    </div>
                    <div className="active content">
                      <div className="accordion">
                        {a
                          .split(". ")
                          .slice(1)
                          .map((b, idx) => {
                            if (b.includes("Tip")) {
                              return (
                                <span key={`tip-${idx}`} className="ui purple center right ribbon label">
                                  {b.substring(0, 50)}
                                  <br></br>
                                  {b.substring(50)}
                                </span>
                              );
                            } else {
                              return (
                                <div key={`ins ${idx}`} className="active title">
                                  <i className="star half alternate icon"></i>
                                  {b}
                                </div>
                              );
                            }
                          })}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="ui button"
              onClick={() => {
                setShow1(false);
              }}
            >
              Close
            </div>
            {collectionUser.split && collectionUser.split.flatMap((o) => o.exercises) && collectionUser.split.flatMap((o) => o.exercises).filter((a) => a.move.name === selectedObj.name).length > 0 && (
              <div
                className="ui button"
                onClick={() => {
                  DeleteWorkout(selectedObj);
                }}
              >
                Delete
              </div>
            )}
          </Modal.Footer>
        </Modal>
        <Modal id="itemsaved" size="lg" show={showsaved} centered>
          <Modal.Header>
            <div className="content">
              <div className="header info-title">{selectedObj.name}</div>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowSaved(false);
              }}
            ></button>
          </Modal.Header>
          <Modal.Body>
            <div className="content">
              <div className="header info-title">Impacted Muscle Groups</div>
            </div>
            {collectionUser.split && collectionUser.split.flatMap((o) => o.exercises) && collectionUser.split.flatMap((o) => o.exercises).length > 0 && (
              <div className="ui horizontal list">
                {collectionUser.split &&
                  collectionUser.split.flatMap((o) => o.exercises) &&
                  collectionUser.muscles.map((a) => {
                    const pm_match = collectionUser.split
                      .flatMap((o) => o.exercises)
                      .filter((g) => g.move.primaryMuscles.includes(a))
                      .map((i) => i.sets)
                      .reduce((sum, el) => sum + el, 0);
                    const sm_match = collectionUser.split
                      .flatMap((o) => o.exercises)
                      .filter((g) => g.move.secondaryMuscles.includes(a))
                      .map((i) => i.sets)
                      .reduce((sum, el) => sum + el, 0);
                    let muscle_count = pm_match + sm_match * 0.5;
                    if (pm_match || sm_match) {
                      return (
                        <div key={a} className="item">
                          <div className="content">
                            <div className="ui sub header">
                              {muscle_count}/{collectionUser.frequency_sets}
                            </div>
                            {a}
                          </div>
                        </div>
                      );
                    } else {
                      return "";
                    }
                  })}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div
              className="ui button"
              onClick={() => {
                setShowSaved(false);
              }}
            >
              Close
            </div>
            {collectionUser.split && collectionUser.split.flatMap((o) => o.exercises) && collectionUser.split.flatMap((o) => o.exercises).filter((a) => a.move.name === selectedObj.name).length > 0 && (
              <div className="item">
                <div
                  className="ui button"
                  onClick={() => {
                    CompleteWorkout(collectionUser.split.flatMap((o) => o.exercises).find((a) => a.move.name === selectedObj.name));
                  }}
                >
                  {" "}
                  Mark As Complete
                </div>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="schedule" aria-labelledby="offcanvasLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasLabel">
              My Workout
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                document.getElementById("schedule").classList.remove("show");
              }}
            ></button>
          </div>
          <div className="offcanvas-body">
            {collectionUser.split && (
              <div className="content">
                <div className="header info-title">System schedule</div>
                <div className="meta">Sets Per Muscle: {collectionUser.frequency_sets}</div>
                <div className="meta">
                  Exercises: {collectionUser.split && collectionUser.split.flatMap((o) => o.exercises).filter((i) => i.iscompleted === true).length}/{collectionUser.split && collectionUser.split.flatMap((o) => o.exercises).length}
                </div>
                <div className="meta">
                  {(currentForce[0] || currentLevel[0] || currentMechanic[0] || currentEquipment[0] || currentMuscles[0] || currentCategory[0]) && (
                    <div className="ui basic label" title="Reset Filters" role="button" onClick={() => handleCollectionChange("force", "") + handleCollectionChange("level", "") + handleCollectionChange("mechanic", "") + handleCollectionChange("equipment", "") + handleCollectionChange("muscles", "") + handleCollectionChange("secondaryMuscles", "") + handleCollectionChange("category", "")}>
                      Reset filters
                    </div>
                  )}
                  {collectionForce
                    .filter((a) => currentForce.includes(a))
                    .map((g) => (
                      <div className="ui basic label" role="button" key={g} onClick={() => handleCollectionChange("force", g)}>
                        Remove {g}
                      </div>
                    ))}
                  {collectionLevel
                    .filter((a) => currentLevel.includes(a))
                    .map((g) => (
                      <div className="ui basic label" role="button" key={g} onClick={() => handleCollectionChange("level", g)}>
                        Remove {g}
                      </div>
                    ))}
                  {collectionMechanic
                    .filter((a) => currentMechanic.includes(a))
                    .map((g) => (
                      <div className="ui basic label" role="button" key={g} onClick={() => handleCollectionChange("mechanic", g)}>
                        Remove {g}
                      </div>
                    ))}
                  {collectionEquipment
                    .filter((a) => currentEquipment.includes(a))
                    .map((g) => (
                      <div className="ui basic label" role="button" key={g} onClick={() => handleCollectionChange("equipment", g)}>
                        Remove {g}
                      </div>
                    ))}
                  {collectionMuscles
                    .filter((a) => currentMuscles.includes(a))
                    .map((g) => (
                      <div className="ui basic label" role="button" key={g} onClick={() => handleCollectionChange("secondaryMuscles", g)}>
                        Remove {g}
                      </div>
                    ))}
                  {collectionCategory
                    .filter((a) => currentCategory.includes(a))
                    .map((g) => (
                      <div className="ui basic label" role="button" key={g} onClick={() => handleCollectionChange("category", g)}>
                        Remove {g}
                      </div>
                    ))}
                </div>
                <div className="ui tree accordion">
                  {collectionUser.split &&
                    collectionUser.split.filter((a) => !a.muscles.includes("rest"))
                      .map((split, idx) => {
                        const allAvailable = [...new Set(collectionMain.filter((item) => item.primaryMuscles.some((muscle) => split.muscles.includes(muscle))))];
                        const exercises = split.exercises.filter((item) => item.move.secondaryMuscles.some((muscle) => split.muscles.includes(muscle)) || item.move.primaryMuscles.some((muscle) => split.muscles.includes(muscle)));
                        const completed = exercises.filter((i) => i.iscompleted === true);
                        const notcompleted = exercises.filter((i) => i.iscompleted === false);
                        return (
                          <div key={`schedule-${idx}`} className="ui tree accordion">
                            <div key={`day-${idx}`} className="title" onClick={accordion} ref={myRef}>
                              <i className="dropdown icon"></i>
                              {split.day}: {
                                split.muscles
                                  .filter((a) => a !== "rest")
                                  .map((a) => (
                                    <span key={a}>&nbsp;<p className="ui grey label">
                                      {a}
                                    </p>
                                    </span>
                                  ))}
                            </div>
                            <div className="content">
                              <div key={`exercises-muscles`} className="accordion">
                                <div className="d-flex flex-row align-items-center title" onClick={accordion} ref={myRef}>
                                  <i className="dropdown icon middle aligned"></i>
                                  Excercises Selected For {split.day}
                                </div>
                                <div className="content">
                                  <div className="ui tree accordion" >
                                    <div className="title" onClick={accordion} ref={myRef}>
                                      <i className="dropdown icon"></i>
                                      {completed.length} Completed
                                    </div>
                                    <div className="content">
                                      <div className="ui tree accordion">
                                        {completed.map((b, bIdx) => (
                                          <div key={`direct-muscle-${b.move.name}-${bIdx}`} className="title" onClick={() => selectItem(b.move.name)}>
                                            {b.move.name} {b.sets} Sets
                                            <div className="description">
                                              <span className="ui red text">{b.move.primaryMuscles.join(", ")}</span> <span className="ui blue text">{b.move.secondaryMuscles.join(", ")}</span>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                    <div className="title" onClick={accordion} ref={myRef}>
                                      <i className="dropdown icon"></i>
                                      {notcompleted.length} Not Completed
                                    </div>
                                    <div className="content">
                                      <div className="ui tree accordion">
                                        {notcompleted.map((b, bIdx) => (
                                          <div key={`direct-muscle-${b.move.name}-${bIdx}`} className="title" onClick={() => selectItem(b.move.name)}>
                                            {b.move.name} {b.sets} Sets
                                            <div className="description">
                                              <span className="ui red text">{b.move.primaryMuscles.join(", ")}</span> <span className="ui blue text">{b.move.secondaryMuscles.join(", ")}</span>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div key={`all-muscles`} className="accordion">
                                <div className="d-flex flex-row align-items-center title" onClick={accordion} ref={myRef}>
                                  <i className="dropdown icon middle aligned"></i>
                                  Excercises For All Muscles
                                </div>
                                <div className="content">
                                  <div className="ui middle aligned divided list">
                                    {allAvailable.map((b, bIdx) => (
                                      <div key={`direct-muscle-${b.name}-${bIdx}`} className="item p-1"
                                        style={{ background: "#fff", cursor: "pointer", boxShadow: "0 1px 2px 0 rgba(34, 36, 38, 0.15), 0 0 0 1px rgba(34, 36, 38, 0.15)", borderRadius: "0.28571429rem", borderWidth: "0 0 1px 0" }}>
                                        <div className="right floated content" style={{ height: "100%" }} onClick={() => AddWorkout(b, collectionUser.exercise_sets, split, `all-${b.name}-${bIdx}`)}>
                                          <div className="ui icon button m-0"><i id={`all-${b.name}-${bIdx}`} className="plus icon"></i></div>
                                        </div>
                                        <div className="content" onClick={() => selectItem(b.name)} >
                                          {b.name}
                                          <div className="description">
                                            <span className="ui red text">{b.primaryMuscles.join(", ")}</span> <span className="ui blue text">{b.secondaryMuscles.join(", ")}</span>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              {split.muscles.map((a, sIdx) => {
                                const malldirect = [...new Set(allAvailable.filter((item) => !item.secondaryMuscles.includes(a) && item.primaryMuscles.includes(a)))];
                                const pm_match = collectionUser.split.flatMap((o) => o.exercises).filter((g) => g.move.primaryMuscles.includes(a) && g.iscompleted === true).map((i) => i.sets).reduce((sum, el) => sum + el, 0);
                                const sm_match = collectionUser.split.flatMap((o) => o.exercises).filter((g) => g.move.secondaryMuscles.includes(a) && g.iscompleted === true).map((i) => i.sets).reduce((sum, el) => sum + el, 0);
                                const match_p = collectionUser.split.flatMap((o) => o.exercises).filter((g) => g.move.primaryMuscles.includes(a) && g.iscompleted === false).map((i) => i.sets).reduce((sum, el) => sum + el, 0);
                                const match_s = collectionUser.split.flatMap((o) => o.exercises).filter((g) => g.move.secondaryMuscles.includes(a) && g.iscompleted === false).map((i) => i.sets).reduce((sum, el) => sum + el, 0);
                                const muscle_count = pm_match + sm_match * 0.5;
                                const muscle_count1 = match_p + match_s * 0.5;
                                return (
                                  <div key={`muscle-${a}-${sIdx}`} className="accordion">
                                    <div className="d-flex flex-row align-items-center title" onClick={accordion} ref={myRef}>
                                      <i className="dropdown icon middle aligned"></i>
                                      {a} (<span className="ui red text">{muscle_count1 > 0 && '+' + muscle_count1}</span>&nbsp;<span className="ui blue text">{muscle_count}/{collectionUser.frequency_sets}</span>)
                                    </div>
                                    <div className="content">
                                      <div className="ui middle aligned divided list">
                                        {malldirect.map((b, bIdx) => (
                                          <div key={`direct-muscle-${b.name}-${bIdx}`} className="item p-1"
                                            style={{ background: "#fff", cursor: "pointer", boxShadow: "0 1px 2px 0 rgba(34, 36, 38, 0.15), 0 0 0 1px rgba(34, 36, 38, 0.15)", borderRadius: "0.28571429rem", borderWidth: "0 0 1px 0" }}>
                                            <div className="right floated content" style={{ height: "100%" }} onClick={() => AddWorkout(b, collectionUser.exercise_sets, split, `mscl-${b.name}-${bIdx}`)}>
                                              <div className="ui icon button m-0"><i id={`mscl-${b.name}-${bIdx}`} className="plus icon"></i></div>
                                            </div>
                                            <div className="content" onClick={() => selectItem(b.name)} >
                                              {b.name}
                                              <div className="description">
                                                <span className="ui red text">{b.primaryMuscles.join(", ")}</span> <span className="ui blue text">{b.secondaryMuscles.join(", ")}</span>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="filters" aria-labelledby="offcanvasLabel1">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasLabel">
              Workout Filters
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                document.getElementById("filters").classList.remove("show");
              }}
            ></button>
          </div>
          <div className="offcanvas-body">
            <div className="header info-title">System List Filter</div>
            <div className="meta">Filter the exercises and muscles that you see. Stretching is considered a category!</div>
            <div className="meta">
              {collectionMain.length}&nbsp;{currentCategory[0]} Exercises
            </div>
            <div className="divider"></div>
            <div className="meta">
              {collectionUser.name && (
                <div className="ui right floated animated basic button" tabIndex="0" onClick={() => OptimalWorkout()}>
                  <div className="visible content">Optimal Workout</div>
                  <div className="hidden content">
                    <i className="right arrow icon"></i>
                  </div>
                </div>
              )}
              {(currentForce[0] || currentLevel[0] || currentMechanic[0] || currentEquipment[0] || currentMuscles[0] || currentCategory[0]) && (
                <div className="ui basic label" title="Reset Filters" role="button" onClick={() => handleCollectionChange("force", "") + handleCollectionChange("level", "") + handleCollectionChange("mechanic", "") + handleCollectionChange("equipment", "") + handleCollectionChange("muscles", "") + handleCollectionChange("category", "")}>
                  Reset filters
                </div>
              )}
              {collectionForce
                .filter((a) => currentForce.includes(a))
                .map((g) => (
                  <div className="ui basic label" role="button" key={g} onClick={() => handleCollectionChange("force", g)}>
                    Remove {g}
                  </div>
                ))}

              {collectionLevel
                .filter((a) => currentLevel.includes(a))
                .map((g) => (
                  <div className="ui basic label" role="button" key={g} onClick={() => handleCollectionChange("level", g)}>
                    Remove {g}
                  </div>
                ))}
              {collectionMechanic
                .filter((a) => currentMechanic.includes(a))
                .map((g) => (
                  <div className="ui basic label" role="button" key={g} onClick={() => handleCollectionChange("mechanic", g)}>
                    Remove {g}
                  </div>
                ))}
              {collectionEquipment
                .filter((a) => currentEquipment.includes(a))
                .map((g) => (
                  <div className="ui basic label" role="button" key={g} onClick={() => handleCollectionChange("equipment", g)}>
                    Remove {g}
                  </div>
                ))}

              {collectionCategory
                .filter((a) => currentCategory.includes(a))
                .map((g) => (
                  <div className="ui basic label" role="button" key={g} onClick={() => handleCollectionChange("category", g)}>
                    Remove {g}
                  </div>
                ))}
              {collectionMuscles
                .filter((a) => currentMuscles.includes(a))
                .map((g) => (
                  <div className="ui basic label" role="button" key={g} onClick={() => handleCollectionChange("muscles", g)}>
                    Remove {g}
                  </div>
                ))}
            </div>

            <div className="ui divided selection list">
              <div className="ui tree accordion">
                <div className="title" onClick={accordion} ref={myRef}>
                  <i className="dropdown icon"></i>
                  Schedule
                </div>
                <div className="content">
                  <div className="ui horizontal list">
                    {collectionUser.schedule &&
                      collectionUser.schedule.map((a, idx) => (
                        <div key={idx} className="item">
                          <div className="content">
                            <button className="ui primary basic button" onClick={() => scheduleFilter(idx)}>
                              {a}
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="ui tree accordion">
                <div className="title" onClick={accordion} ref={myRef}>
                  <i className="dropdown icon"></i>
                  Muscles
                </div>
                <div className="content">
                  <div className="ui horizontal list">
                    {collectionMuscles.map((a) => (
                      <div key={a} className="item">
                        <div className="content">
                          <button className={`ui ${currentMuscles.includes(a) ? "red" : "primary"} basic button`} onClick={() => handleCollectionChange("muscles", a)}>
                            {a}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="ui tree accordion">
                <div className="title" onClick={accordion} ref={myRef}>
                  <i className="dropdown icon"></i>
                  Exercise Type
                </div>
                <div className="content">
                  <div className="ui horizontal list">
                    {collectionCategory.map((a) => (
                      <div key={a} className="item">
                        <div className="content">
                          <button className={`ui ${currentCategory.includes(a) ? "red" : "primary"} basic button`} onClick={() => handleCollectionChange("category", a)}>
                            {a}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="ui tree accordion">
                <div className="title" onClick={accordion} ref={myRef}>
                  <i className="dropdown icon"></i>
                  Movement Family
                </div>
                <div className="content">
                  <div className="ui horizontal list">
                    {collectionForce.map((a) => (
                      <div key={a} className="item">
                        <div className="content">
                          <button className={`ui ${currentForce.includes(a) ? "red" : "primary"} basic button`} onClick={() => handleCollectionChange("force", a)}>
                            {a}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="ui tree accordion">
                <div className="title" onClick={accordion} ref={myRef}>
                  <i className="dropdown icon"></i>
                  Group or Isolated
                </div>
                <div className="content">
                  <div className="ui horizontal list">
                    {collectionMechanic.map((a) => (
                      <div key={a} className="item">
                        <div className="content">
                          <button className={`ui ${currentMechanic.includes(a) ? "red" : "primary"} basic button`} onClick={() => handleCollectionChange("mechanic", a)}>
                            {a}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="ui tree accordion">
                <div className="title" onClick={accordion} ref={myRef}>
                  <i className="dropdown icon"></i>
                  Difficulty Level
                </div>
                <div className="content">
                  <div className="ui horizontal list">
                    {collectionLevel.map((a) => (
                      <div key={a} className="item">
                        <div className="content">
                          <button className={`ui ${currentLevel.includes(a) ? "red" : "primary"} basic button`} onClick={() => handleCollectionChange("level", a)}>
                            {a}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="ui tree accordion">
                <div className="title" onClick={accordion} ref={myRef}>
                  <i className="dropdown icon"></i>
                  Equipment
                </div>
                <div className="content">
                  <div className="ui horizontal list">
                    {collectionEquipment.map((a) => (
                      <div key={a} className="item">
                        <div className="content">
                          <button className={`ui ${currentEquipment.includes(a) ? "red" : "primary"} basic button`} onClick={() => handleCollectionChange("equipment", a)}>
                            {a}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="content">
                <button className="ui secondary basic button" onClick={() => (window.location.href = "/CytoScapeGen")}>
                  🐇🥚
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="segment">
        <div className="ui horizontal list">
          {collectionUser.name && (
            <div className="ui animated basic button" onClick={() => { document.getElementById("schedule").classList.add("show"); }}>
              <div className="visible content">My Workouts</div>
              <div className="hidden content">
                <i className="left arrow icon"></i>
              </div>
            </div>
          )}
          <div className="ui animated basic button" onClick={() => { document.getElementById("filters").classList.add("show"); }}>
            <div className="visible content">Filter Workouts</div>
            <div className="hidden content">
              <i className="right arrow icon"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="ui top attached tabular menu" id="myTab">
        <button className="item active" onClick={() => OpenTab("overview")} id="overview-tab">
          Exercises
        </button>
        <button className="item" onClick={() => OpenTab("muscles")} id="muscles-tab">
          Muscles
        </button>
        <button className="item" onClick={() => OpenTab("profile")} id="profile-tab">
          {collectionUser.name ? "Profile" : "Register"}
        </button>
      </div>
      <div className="tab-content ui bottom attached active tab segment" id="myTabContent">
        <div className="tab-pane fade show active" id="overview-tab-pane">
          <div className="ui center aligned header">
            <div className="content">
              <h1 className="header">Exercises</h1>
              <div className="sub header">
                {collectionMain.length}&nbsp;{currentCategory[0]} Exercises
              </div>
            </div>
          </div>
          <div className="ui stackable aligned grid">
            <div className="center aligned one column row">
              <div className="column">
                <div className="ui left icon input">
                  <input type="search" id="SearchInput" style={{ fontSize: "16px" }} placeholder="Search..." onChange={(event) => searchFunction(event.target.value)} />
                  <i className="search icon"></i>
                </div>
              </div>
            </div>
            <div className=" two column row">
              <div className="left aligned column">
                {collectionMain.length !== 873 && (
                  <div className="ui animated basic button" onClick={() => ShowWorkouts(0)}>
                    <div className="visible content">All Excercises</div>
                    <div className="hidden content">
                      <i className="right arrow icon"></i>
                    </div>
                  </div>
                )}
              </div>
              <div className="right aligned column">
                {collectionUser.split && collectionUser.split.flatMap((o) => o.exercises) && collectionUser.split.flatMap((o) => o.exercises).length > 0 && (
                  <div className="ui animated basic button" onClick={() => ShowWorkouts(1)}>
                    <div className="visible content">My Exercise List</div>
                    <div className="hidden content">
                      <i className="right arrow icon"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="ui divider"></div>
          <div className="ui cards">
            {collectionMain
              .slice((currentPage - 1) * PerPage, (currentPage - 1) * PerPage + PerPage)
              .sort((a, b) => {
                return b.secondaryMuscles.length - a.secondaryMuscles.length;
              })
              .map((item, idx) => (
                <div key={`MainCard${item.name}`} className="card stackable5" role="button">
                  <div className="content">
                    <div key={item.name} className="header" onClick={() => selectItem(item.name)}>
                      <div className="header">{item.name}</div>
                      <div className="description">
                        <div className="ui horizontal list">
                          {item.category &&
                            <div className="item" style={{ fontSize: "12px" }}>
                              <div className="content" style={{ color: currentCategory.length !== 0 ? "blue" : "black" }}>
                                <div className="ui sub header">{item.category}</div>
                                Category
                              </div>
                            </div>}
                          {item.force &&
                            <div className="item" style={{ fontSize: "12px" }}>
                              <div className="content" style={{ color: currentForce.length !== 0 ? "blue" : "black" }}>
                                <div className="ui sub header">{item.force}</div>
                                Force
                              </div>
                            </div>}
                          {item.level &&
                            <div className="item" style={{ fontSize: "12px" }}>
                              <div className="content" style={{ color: currentLevel.length !== 0 ? "blue" : "black" }}>
                                <div className="ui sub header">{item.level}</div>
                                Level
                              </div>
                            </div>}
                          {item.mechanic &&
                            <div className="item" style={{ fontSize: "12px" }}>
                              <div className="content" style={{ color: currentMechanic.length !== 0 ? "blue" : "black" }}>
                                <div className="ui sub header">{item.mechanic ? item.mechanic : "None"}</div>
                                Mechanic
                              </div>
                            </div>}
                          {item.equipment &&
                            <div className="item" style={{ fontSize: "12px" }}>
                              <div className="content" style={{ color: currentEquipment.length !== 0 ? "blue" : "black" }}>
                                <div className="ui sub header">{item.equipment}</div>
                                Equipment
                              </div>
                            </div>}
                        </div>
                      </div>
                    </div>
                    <div className="description">
                      <div className="ui horizontal list">
                        <div className="item" style={{ fontSize: "12px" }}>
                          <div className="content p-1" style={{ border: currentMuscles.length !== 0 ? "1px solid red" : "0", borderRadius: "5px" }}>
                            <div className="ui sub header">{item.primaryMuscles}</div>
                            Primary Muscle
                          </div>
                        </div>
                        <div className="item" style={{ fontSize: "12px" }}>
                          <div className="content p-1" style={{ border: currentMuscles.length !== 0 ? "1px solid red" : "0", borderRadius: "5px" }}>
                            <div className="ui sub header"> {item.secondaryMuscles[0] ? item.secondaryMuscles.join(", ") : "None"}</div>
                            Secondary Muscles
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="ui grid">
            <div className=" one column row">
              <div className="center aligned column">
                <div className="ui pagination menu">
                  <span className={`icon item ${currentPage === 1 && "disabled"}`} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    <i className="left chevron icon"></i>
                  </span>
                  {currentPage > Math.ceil(maxPagesToShow / 2) && (
                    <span className="item" onClick={() => handlePageChange(1)}>
                      1
                    </span>
                  )}
                  {currentPage > Math.ceil(maxPagesToShow / 2) && <span className="item">...</span>}
                  {getVisiblePages().map((page) => (
                    <span key={page} onClick={() => handlePageChange(page)} className={`icon item ${currentPage === page && "active"}`}>
                      {page}
                    </span>
                  ))}
                  {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && <span className="item">...</span>}
                  {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && (
                    <span className="item" onClick={() => handlePageChange(totalPages)}>
                      {totalPages}
                    </span>
                  )}
                  <span className={`icon item ${currentPage === totalPages && "disabled"}`} onClick={() => handlePageChange(currentPage + 1)}>
                    <i className="right chevron icon"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="muscles-tab-pane">
          <div className="ui segment">
            <p className="text-center">
              <i className="copyright outline icon"></i>
              <span className="ui large purple text">Roy's Total Balance Muscle Hypertrophy System (RTBMHS)</span>
            </p>
            <p>
              <span className="ui purple text">RTBMHS</span> is the process of increasing your fitness level with research-based training. The goal will be to complete a total of <span className="ui purple text">12 Sets</span> per muscle per week. Using a variation of classifications defined by a study from{" "}
              <a target="_blank" rel="noreferrer" href="https://sportrxiv.org/index.php/server/preprint/view/460/967">
                <span className="ui purple text">Florida Atlantic University</span>
              </a>{" "}
              to measure muscle sets. Where weekly sets for muscles are scaled by their attribution to the exercise i.e. <span className="ui purple text">Primary Muscle</span> sets are 1:1 and <span className="ui purple text">Secondary Muscle</span> sets are 1:2.
            </p>
            <p className="">Glossary</p>
            <div className="ui tree accordion">
              <div className="title" onClick={accordion} ref={myRef}>
                <i className="dropdown icon"></i>
                Primary Muscle
              </div>
              <div className="content">
                <p>The primary muscle activated for that exercise.</p>
              </div>
              <div className="title" onClick={accordion} ref={myRef}>
                <i className="dropdown icon"></i>
                Secondary Muscle
              </div>
              <div className="content">
                <p>A single or group of muscles that will be activated during the exercise.</p>
              </div>
              <div className="title" onClick={accordion} ref={myRef}>
                <i className="dropdown icon"></i>
                Direct Exercises
              </div>
              <div className="content">
                <p>The direct exercise list, is generated by selecting exercises that target the primary muscle.</p>
              </div>
              <div className="title" onClick={accordion} ref={myRef}>
                <i className="dropdown icon"></i>
                Indirect Exercises
              </div>
              <div className="content">
                <p>
                  The indirect exercise list, is generated by selecting exercises that target the secondary muscle. Note: indirect and direct list are <span className="ui red text">NOT</span> mutually exclusive.
                </p>
              </div>
              <div className="title" onClick={accordion} ref={myRef}>
                <i className="dropdown icon"></i>
                SuperSet
              </div>
              <div className="content">
                <p>
                  Pair or a grouping of exercises completed in sequence before rest. A study from{" "}
                  <a target="_blank" rel="noreferrer" href="https://sportrxiv.org/index.php/server/preprint/view/419">
                    <span className="ui purple text">CUNY Lehman College, Robert Gordon University </span>
                  </a>
                  Analyzed the effects on the body between supersets and traditional sets. The conclusion was that while the training time was reduced for supersets the overall health benefits are not statistically significant.
                </p>
              </div>
              <div className="title" onClick={accordion} ref={myRef}>
                <i className="dropdown icon"></i>
                Concerns on 'Cheat Reps'
              </div>
              <div className="content">
                <p>
                  A study from{" "}
                  <a target="_blank" rel="noreferrer" href="https://sportrxiv.org/index.php/server/preprint/view/497">
                    <span className="ui purple text">CUNY Lehman College</span>
                  </a>{" "}
                  analyzed whether cheat reps had a significant handicap on muscle training. No statistical significance was found between cheat reps and normal reps.
                </p>
              </div>
            </div>
          </div>
          <div className="ui centered aligned heading">
            <h1 className="header">Primary Muscle List</h1>
            <div className="meta">
              <span className="date">
                {collectionMain.length}&nbsp;{currentCategory[0]} Exercises
              </span>
            </div>
          </div>
          <div className="ui divider"></div>
          <div className="ui cards">
            <div className="card stackable">
              <div className="content">
                <div className="header info-title">Muscle</div>
                <div className="meta">Direct Exercises: (Total count where this muscle is the primary muscle)</div>
                <div className="ui tree accordion">
                  <span className="ui blue center right ribbon label">
                    Exercises targeting the secondary muscles<br></br> and percentage of inclusion.
                  </span>
                  <div className="active title" onClick={accordion} ref={myRef}>
                    <i className="dropdown icon"></i>
                    Compound Primary Muscle
                  </div>
                  <div className="active content">
                    <div className="accordion">
                      <span className="ui teal center right ribbon label">The muscle and its percentage of inclusion.</span>

                      <div className="active title" onClick={accordion} ref={myRef}>
                        <i className="dropdown icon"></i>
                        Secondary Muscle 10%
                      </div>
                      <div className="active content">
                        <span className="ui purple center right ribbon label">View the exercise</span>
                        <div className="ui tree accordion">
                          <div className="title">
                            <i className="bullseye icon"></i>
                            Exercise
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="ui blue center right ribbon label">
                    Exercises targeting only the primary muscle<br></br> and percentage of inclusion.
                  </span>
                  <div className="active title" onClick={accordion} ref={myRef}>
                    <i className="dropdown icon"></i>
                    Isolated Primary Muscle
                  </div>
                  <div className="active content">
                    <span className="ui purple center right ribbon label">View the exercise.</span>
                    <div className="ui tree accordion">
                      <div className="title">
                        <i className="bullseye icon"></i>
                        Exercise
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {collectionMuscles
              .sort((a, b) => {
                let directExercisesA = collectionMain.filter((i) => i.primaryMuscles.map((g) => g).includes(a));
                let IsolatedperA = ((directExercisesA.filter((i) => i.secondaryMuscles.length === 0).length / directExercisesA.length) * 100).toFixed(0);
                let directExercisesB = collectionMain.filter((i) => i.primaryMuscles.map((g) => g).includes(b));
                let IsolatedperB = ((directExercisesB.filter((i) => i.secondaryMuscles.length === 0).length / directExercisesB.length) * 100).toFixed(0);
                return IsolatedperA - IsolatedperB;
              })
              .map((a) => {
                let directExercises = collectionMain.filter((b) => b.primaryMuscles.map((g) => g).includes(a)); //let group1 = collection.filter((b) => b.secondaryMuscles.map((g) => g).includes(a) && false);
                let secondaryMuscles = [...new Set(directExercises.map((a) => a.secondaryMuscles).flat())];
                let Isolatedper = ((directExercises.filter((b) => b.secondaryMuscles.length === 0).length / directExercises.length) * 100).toFixed(0);
                return (
                  <div key={a} className={`card stackable ${currentMuscles.includes(a) && "active2"}`}>
                    <div className="content">
                      <div className="header info-title">{a}</div>
                      <div className="meta">Direct Exercises: {directExercises.length}</div>
                      <div className="ui tree accordion text-capitalize">
                        <div className="active title" onClick={accordion} ref={myRef}>
                          <i className="dropdown icon"></i>
                          Compound {a} {100 - Isolatedper}%
                        </div>
                        <div className="active content">
                          <div className="accordion">
                            {secondaryMuscles
                              .map((x) => {
                                let prcnt = (directExercises.filter((b) => b.primaryMuscles.map((g) => g).includes(x) || b.secondaryMuscles.map((g) => g).includes(x)).length / directExercises.length) * 100;
                                return { muscle: x, percentage: prcnt.toFixed(0), total: directExercises.filter((b) => b.primaryMuscles.map((g) => g).includes(x) || b.secondaryMuscles.map((g) => g).includes(x)).length };
                              })
                              .sort((a, b) => b.percentage - a.percentage)
                              .map((i) => (
                                <div key={i.muscle} className="ui tree accordion">
                                  <div className="title" onClick={accordion} ref={myRef}>
                                    <i className="dropdown icon"></i>
                                    {i.muscle} {i.percentage}%
                                  </div>
                                  <div className="content">
                                    <div className="ui tree accordion">
                                      {directExercises
                                        .filter((b) => b.primaryMuscles.map((g) => g).includes(i.muscle) || b.secondaryMuscles.map((g) => g).includes(i.muscle))
                                        .map((b) => (
                                          <div key={b.name} className="ui tree accordion">
                                            <div className="title" onClick={() => selectItem(b.name)}>
                                              <i className="bullseye icon"></i>
                                              {b.name}
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="title" onClick={accordion} ref={myRef}>
                          <i className="dropdown icon"></i>
                          Isolated {a} {Isolatedper}%
                        </div>
                        <div className="content">
                          <div className="ui tree accordion">
                            {directExercises
                              .filter((b) => b.secondaryMuscles.length === 0)
                              .map((b) => (
                                <div key={b.name} className="ui tree accordion">
                                  <div className="title" onClick={() => selectItem(b.name)}>
                                    <i className="bullseye icon"></i>
                                    {b.name}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="tab-pane fade" id="profile-tab-pane">
          <div className="ui stackable aligned grid">
            <div className="two column row">
              <div className="column">
                <div className="ui horizontal list">
                  <div className="item">
                    <img className="ui mini circular image" src="apple-touch-icon.png" alt="profile" />
                    <div className="content">
                      <div className="ui sub header">{collectionUser.name ? collectionUser.name : "Username"}</div>
                      Since {collectionUser.create_date ? collectionUser.create_date.substring(0, 4) : new Date(Date.now()).toISOString().substring(0, 4)}
                    </div>
                  </div>
                </div>
                <div className="ui divider"></div>
                <div className="ui horizontal list">
                  <div className="item">
                    <div className="content">
                      <div className="ui sub header">{collectionUser.age ? collectionUser.age : "Age"}</div>
                      Age
                    </div>
                  </div>
                  <div className="item">
                    <div className="content">
                      <div className="ui sub header">{collectionUser.weight ? collectionUser.weight : "Weight"}</div>
                      Weight
                    </div>
                  </div>
                  <div className="item">
                    <div className="content">
                      <div className="ui sub header">{collectionUser.frequency_reps ? collectionUser.frequency_reps : "Reps"}</div>
                      Reps
                    </div>
                  </div>
                  <div className="item">
                    <div className="content">
                      <div className="ui sub header">{collectionUser.frequency_sets ? collectionUser.frequency_sets : "Sets"}</div>
                      Sets
                    </div>
                  </div>
                  <div className="item">
                    <div className="content">
                      <div className="ui sub header">{collectionUser.split && collectionUser.split.flatMap((o) => o.exercises) ? collectionUser.split.flatMap((o) => o.exercises).filter((i) => i.iscompleted === true).length + "/" + collectionUser.split.flatMap((o) => o.exercises).length : "Exercises"}</div>
                      Exercises
                    </div>
                  </div>
                </div>
                <div className="ui divider" style={{ opacity: "0" }}></div>
                <div className="ui horizontal list">
                  <button
                    type="button"
                    className="ui basic button"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    {collectionUser.name ? "Edit" : "New User"}
                  </button>
                  {collectionUser.name && (
                    <button
                      type="button"
                      className="ui basic button"
                      onClick={() => {
                        localStorage.clear();
                        setUserCollection({});
                      }}
                    >
                      Delete
                    </button>
                  )}
                </div>
                <div className="ui tree accordion">
                  <div className="title" onClick={accordion} ref={myRef}>
                    <i className="dropdown icon"></i>
                    Settings
                  </div>
                  <div className="content">
                    <div className="ui horizontal list">
                      {collectionUser.split && collectionUser.split.flatMap((o) => o.exercises) && (
                        collectionUser.split && collectionUser.split.flatMap((o) => o.exercises) && collectionUser.split.flatMap((o) => o.exercises).filter((i) => i.iscompleted === true).length > 0 && (
                          <button type="button" className="ui basic button" onClick={() => DeleteAllWorkout()}>
                            Delete Exercises
                          </button>
                        )
                      )}
                      <button type="button" className="ui basic button" onClick={() => NewWeek()}>
                        New Week
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="center aligned column">
                {collectionUser.split
                  ? bodymuscle(
                    collectionUser.split.flatMap(a => a.muscles).map((a) => {
                      const pm_match = collectionUser.split
                        .flatMap((o) => o.exercises)
                        .filter((i) => i.iscompleted === true)
                        .filter((g) => g.move.primaryMuscles.includes(a))
                        .map((i) => i.sets)
                        .reduce((sum, el) => sum + el, 0);

                      const sm_match = collectionUser.split
                        .flatMap((o) => o.exercises)
                        .filter((i) => i.iscompleted === true)
                        .filter((g) => g.move.secondaryMuscles.includes(a))
                        .map((i) => i.sets)
                        .reduce((sum, el) => sum + el, 0);
                      let muscle_count = pm_match + sm_match * 0.5;
                      return { muscle: a, count: muscle_count };
                    })
                  )
                  : bodymuscle([])}
                <div className="ui horizontal list m-2">
                  <span className="ui inverted label" style={{ background: "#800000" }}>
                    Fully Neglected
                  </span>
                  <span className="ui orange inverted label">Just Started</span>
                  <span className="ui olive inverted label">Almost Done!</span>
                  <span className="ui green inverted label">Complete!</span>
                </div>
              </div>
            </div>
            <div className="one column row">
              <div className="column">
                <div className="ui tree accordion">
                  <div className="title" onClick={accordion} ref={myRef}>
                    <i className="dropdown icon"></i>
                    Adjust Your Exercises
                  </div>
                  <div className="content">
                    <div className="ui tree accordion">
                      <div className="title" onClick={accordion} ref={myRef}>
                        <i className="dropdown icon"></i>
                        All Exercises
                      </div>
                      <div className="content">
                        <div className="accordion">
                          <div className="title" onClick={accordion} ref={myRef}>
                            <i className="dropdown icon"></i>
                            All Excercises Completed {collectionUser.split && collectionUser.split.flatMap((o) => o.exercises).filter((i) => i.iscompleted === true).length}
                          </div>
                          <div className="content">
                            <div className="ui tree accordion">
                              {collectionUser.split &&
                                collectionUser.split
                                  .flatMap((o) => o.exercises)
                                  .filter((i) => i.iscompleted === true)
                                  .map((b, bIdx) => (
                                    <div key={`direct-muscle-${b.move.name}-${bIdx}`} className="title" onClick={() => selectItem(b.move.name)}>
                                      {b.move.name} {b.sets} Sets
                                      <div className="description">
                                        <span className="ui red text">{b.move.primaryMuscles.join(", ")}</span>
                                      </div>
                                      <div className="description">
                                        <span className="ui blue text">{b.move.secondaryMuscles.join(", ")}</span>
                                      </div>
                                    </div>
                                  ))}
                            </div>
                          </div>
                          <div className="title" onClick={accordion} ref={myRef}>
                            <i className="dropdown icon"></i>
                            Incomplete Excercises {collectionUser.split && collectionUser.split.flatMap((o) => o.exercises).filter((i) => i.iscompleted === false).length}
                          </div>
                          <div className="content">
                            <div className="ui tree accordion">
                              {collectionUser.split &&
                                collectionUser.split
                                  .flatMap((o) => o.exercises)
                                  .filter((i) => i.iscompleted === false)
                                  .map((b, bIdx) => (
                                    <div key={`direct-muscle-${b.move.name}-${bIdx}`} className="title" onClick={() => selectItem(b.move.name)}>
                                      {b.move.name} {b.sets} Sets
                                      <div className="description">
                                        <span className="ui red text">{b.move.primaryMuscles.join(", ")}</span>
                                      </div>
                                      <div className="description">
                                        <span className="ui blue text">{b.move.secondaryMuscles.join(", ")}</span>
                                      </div>
                                    </div>
                                  ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ui tree accordion">
                      {collectionUser.split &&
                        collectionUser.split.filter((a) => !a.muscles.includes("rest"))
                          .map((split, idx) => {
                            const allAvailable = [...new Set(collectionMain.filter((item) => item.primaryMuscles.some((muscle) => split.muscles.includes(muscle))))];
                            const exercises = split.exercises.filter((item) => item.move.secondaryMuscles.some((muscle) => split.muscles.includes(muscle)) || item.move.primaryMuscles.some((muscle) => split.muscles.includes(muscle)));
                            const completed = exercises.filter((i) => i.iscompleted === true);
                            const notcompleted = exercises.filter((i) => i.iscompleted === false);
                            return (
                              <div key={`schedule-${idx}`} className="ui tree accordion">
                                <div key={`day-${idx}`} className="title" onClick={accordion} ref={myRef}>
                                  <i className="dropdown icon"></i>
                                  {split.day} Excercises For {
                                    split.muscles
                                      .filter((a) => a !== "rest")
                                      .map((a) => (
                                        <span key={a}>&nbsp;<p className="ui grey label">
                                          {a}
                                        </p>
                                        </span>
                                      ))}
                                </div>
                                <div className="content">
                                  <div key={`exercises-muscles`} className="accordion">
                                    <div className="d-flex flex-row align-items-center title" onClick={accordion} ref={myRef}>
                                      <i className="dropdown icon middle aligned"></i>
                                      Excercises Selected For {split.day}
                                    </div>
                                    <div className="content">
                                      <div className="ui tree accordion" >
                                        <div className="title" onClick={accordion} ref={myRef}>
                                          <i className="dropdown icon"></i>
                                          {completed.length} Completed
                                        </div>
                                        <div className="content">
                                          <div className="ui tree accordion">
                                            {completed.map((b, bIdx) => (
                                              <div key={`direct-muscle-${b.move.name}-${bIdx}`} className="title" onClick={() => selectItem(b.move.name)}>
                                                {b.move.name} {b.sets} Sets
                                                <div className="description">
                                                  <span className="ui red text">{b.move.primaryMuscles.join(", ")}</span> <span className="ui blue text">{b.move.secondaryMuscles.join(", ")}</span>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                        <div className="title" onClick={accordion} ref={myRef}>
                                          <i className="dropdown icon"></i>
                                          {notcompleted.length} Not Completed
                                        </div>
                                        <div className="content">
                                          <div className="ui tree accordion">
                                            {notcompleted.map((b, bIdx) => (
                                              <div key={`direct-muscle-${b.move.name}-${bIdx}`} className="title" onClick={() => selectItem(b.move.name)}>
                                                {b.move.name} {b.sets} Sets
                                                <div className="description">
                                                  <span className="ui red text">{b.move.primaryMuscles.join(", ")}</span> <span className="ui blue text">{b.move.secondaryMuscles.join(", ")}</span>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div key={`all-muscles`} className="accordion">
                                    <div className="d-flex flex-row align-items-center title" onClick={accordion} ref={myRef}>
                                      <i className="dropdown icon middle aligned"></i>
                                      Excercises For All Muscles
                                    </div>
                                    <div className="content">
                                      <div className="ui middle aligned divided list">
                                        {allAvailable.map((b, bIdx) => (
                                          <div key={`direct-muscle-${b.name}-${bIdx}`} className="item p-1"
                                            style={{ background: "#fff", cursor: "pointer", boxShadow: "0 1px 2px 0 rgba(34, 36, 38, 0.15), 0 0 0 1px rgba(34, 36, 38, 0.15)", borderRadius: "0.28571429rem", borderWidth: "0 0 1px 0" }}>
                                            <div className="right floated content" style={{ height: "100%" }} onClick={() => AddWorkout(b, collectionUser.exercise_sets, split, `all-${b.name}-${bIdx}`)}>
                                              <div className="ui icon button m-0"><i id={`all-${b.name}-${bIdx}`} className="plus icon"></i></div>
                                            </div>
                                            <div className="content" onClick={() => selectItem(b.name)} >
                                              {b.name}
                                              <div className="description">
                                                <span className="ui red text">{b.primaryMuscles.join(", ")}</span> <span className="ui blue text">{b.secondaryMuscles.join(", ")}</span>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  {split.muscles.map((a, sIdx) => {
                                    const malldirect = [...new Set(allAvailable.filter((item) => !item.secondaryMuscles.includes(a) && item.primaryMuscles.includes(a)))];
                                    const pm_match = collectionUser.split.flatMap((o) => o.exercises).filter((g) => g.move.primaryMuscles.includes(a) && g.iscompleted === true).map((i) => i.sets).reduce((sum, el) => sum + el, 0);
                                    const sm_match = collectionUser.split.flatMap((o) => o.exercises).filter((g) => g.move.secondaryMuscles.includes(a) && g.iscompleted === true).map((i) => i.sets).reduce((sum, el) => sum + el, 0);
                                    const match_p = collectionUser.split.flatMap((o) => o.exercises).filter((g) => g.move.primaryMuscles.includes(a) && g.iscompleted === false).map((i) => i.sets).reduce((sum, el) => sum + el, 0);
                                    const match_s = collectionUser.split.flatMap((o) => o.exercises).filter((g) => g.move.secondaryMuscles.includes(a) && g.iscompleted === false).map((i) => i.sets).reduce((sum, el) => sum + el, 0);
                                    const muscle_count = pm_match + sm_match * 0.5;
                                    const muscle_count1 = match_p + match_s * 0.5;
                                    return (
                                      <div key={`muscle-${a}-${sIdx}`} className="accordion">
                                        <div className="d-flex flex-row align-items-center title" onClick={accordion} ref={myRef}>
                                          <i className="dropdown icon middle aligned"></i>
                                          {a} (<span className="ui red text">
                                            {

                                            }
                                            {muscle_count1 > 0 && '+' + muscle_count1}</span>
                                          &nbsp;<span className="ui blue text">{muscle_count}/{collectionUser.frequency_sets}</span>)
                                        </div>
                                        <div className="content">
                                          <div className="ui middle aligned divided list">
                                            {malldirect.map((b, bIdx) => (
                                              <div key={`direct-muscle-${b.name}-${bIdx}`} className="item p-1"
                                                style={{ background: "#fff", cursor: "pointer", boxShadow: "0 1px 2px 0 rgba(34, 36, 38, 0.15), 0 0 0 1px rgba(34, 36, 38, 0.15)", borderRadius: "0.28571429rem", borderWidth: "0 0 1px 0" }}>
                                                <div className="right floated content" style={{ height: "100%" }} onClick={() => AddWorkout(b, collectionUser.exercise_sets, split, `mscl-${b.name}-${bIdx}`)}>
                                                  <div className="ui icon button m-0"><i id={`mscl-${b.name}-${bIdx}`} className="plus icon"></i></div>
                                                </div>
                                                <div className="content" onClick={() => selectItem(b.name)} >
                                                  {b.name}
                                                  <div className="description">
                                                    <span className="ui red text">{b.primaryMuscles.join(", ")}</span> <span className="ui blue text">{b.secondaryMuscles.join(", ")}</span>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                  <div className="title" onClick={accordion} ref={myRef}>
                    <i className="dropdown icon"></i>
                    Update or Change Muscle Groups
                  </div>
                  <div className="content">
                    <div className="ui tree accordion">
                      {collectionUser.split &&
                        collectionUser.split.filter((a) => !a.muscles.includes("rest")).map((a, idx) => {
                          let available = collectionUser.muscles.filter((b) => !collectionUser.split.flatMap((a) => a.muscles).includes(b));
                          return (
                            <div key={`schedule-${idx}`} className="accordion">
                              <div key={`day-${idx}`} className="title" onClick={accordion} ref={myRef}>
                                <i className="dropdown icon"></i>
                                {a.day}: {
                                  a.muscles
                                    .filter((a) => a !== "rest")
                                    .map((a) => (
                                      <span key={a}>&nbsp;<p className="ui grey label">
                                        {a}
                                      </p>
                                      </span>
                                    ))}
                              </div>
                              <div className="content">
                                <div className="ui middle aligned divided list">
                                  {a.muscles.map((b, bIdx) => (
                                    <div key={`direct-muscle-${b.name}-${bIdx}`} className="item p-1"
                                      style={{ background: "#fff", cursor: "pointer", boxShadow: "0 1px 2px 0 rgba(34, 36, 38, 0.15), 0 0 0 1px rgba(34, 36, 38, 0.15)", borderRadius: "0.28571429rem", borderWidth: "0 0 1px 0" }}>
                                      <div className="right floated content" style={{ height: "100%" }} onClick={() => RemoveMuscle(a, b)}>
                                        <div className="ui icon button m-0"><i id={`direct-${b.name}-${bIdx}`} className="trash icon"></i></div>
                                      </div>
                                      <div className="content" onClick={() => selectItem(b)} >
                                        {b}
                                        <div className="description">
                                          <span className="ui red text">{collectionUser.exercise_sets} Sets Per Exercise</span> <span className="ui blue text">{collectionUser.frequency_sets} Sets Per Muscle</span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  {available.map((b, bIdx) => (
                                    <div key={`direct-muscle-${b.name}-${bIdx}`} className="item p-1 my-3"
                                      style={{ background: "#fff", cursor: "pointer", boxShadow: "0 1px 2px 0 rgba(34, 36, 38, 0.15), 0 0 0 1px rgba(34, 36, 38, 0.15)", borderRadius: "0.28571429rem", borderWidth: "0 0 1px 0" }}>
                                      <div className="right floated content" style={{ height: "100%" }} onClick={() => AddMuscle(a, b)}>
                                        <div className="ui icon button m-0"><i id={`direct-${b.name}-${bIdx}`} className="plus icon"></i></div>
                                      </div>
                                      <div className="content" onClick={() => selectItem(b)} >
                                        Add <span>{b}</span>?
                                        <div className="description">
                                          <span className="ui red text">{collectionUser.exercise_sets} Sets Per Exercise</span> <span className="ui blue text">{collectionUser.frequency_sets} Sets Per Muscle</span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          );
                        })}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Exercise;
