import React, { useState } from "react";

const RatingComponent = () => {
  const [selectedRating, setSelectedRating] = useState(null);

  const handleRadioChange = (index) => {
    setSelectedRating(index);
  };

  return (
    <div className="radio-wrapper-16 w-100">
      {Array(5)
        .fill("☆")
        .reverse()
        .map((r, index) => (
          <React.Fragment key={index}>
            <input
              id={"radio-" + index}
              type="radio"
              name="Rating"
              value={index}
              checked={selectedRating === index}
              onChange={() => handleRadioChange(index)}
            />
            <label htmlFor={"radio-" + index}>
              {selectedRating >= index ? "★" : r}
            </label>
          </React.Fragment>
        ))}
    </div>
  );
};

export default RatingComponent;
