import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";

const Footer = () => {
  const location = useLocation();
  React.useEffect(() => {
    if(location.pathname.includes("&snpcht")){
      window.location.href = "/Exercise";
    }else if(location.pathname.includes("&bsky")){
      window.location.href = "/Exercise";
    }else if(location.pathname.includes("&instgrm")){
      window.location.href = "/Exercise";
    }else if(location.pathname.includes("&thrds")){
      window.location.href = "/Exercise";
    }else if(location.pathname.includes("&tkt")){
      window.location.href = "/Exercise";
    }
  }, [location]);
  return (
    <footer className="footer mt-4">

      <div className="ui stackable aligned grid">
        <div className="center aligned one column row">
          <div className="column">
            <div class="ui compact menu">
              <div className="item">
                <Nav.Link as={Link} to="/Articles" eventKey="/Articles">
                  Articles
                </Nav.Link>
              </div>
              <div className="item">
                <Nav.Link as={Link} to="/Services" eventKey="/Services">
                  Music
                </Nav.Link>
              </div>
              <div className="item">
                <Nav.Link as={Link} to="/Movies" eventKey="/Movies">
                  Movies
                </Nav.Link>
              </div>
              <div className="item">
                <Nav.Link as={Link} to="/Exercise" eventKey="/Exercise">
                  Exercise
                </Nav.Link>
              </div>
            </div>
            <p>COPYRIGHT &copy;2024 ROY</p>
          </div>
        </div>
      </div>
      <div className="container text-center py-3">

      </div>
    </footer>
  );
};

export default Footer;
