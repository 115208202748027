import React from "react";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname.includes("&snpcht")) {
      window.location.href = "/Exercise";
    } else if (location.pathname.includes("&bsky")) {
      window.location.href = "/Exercise";
    } else if (location.pathname.includes("&instgrm")) {
      window.location.href = "/Exercise";
    } else if (location.pathname.includes("&thrds")) {
      window.location.href = "/Exercise";
    } else if (location.pathname.includes("&tkt")) {
      window.location.href = "/Exercise";
    }
  }, [location]);
  return (
    <footer className="footer mt-4">
      <div className="container text-center py-3">
        <p>COPYRIGHT &copy;2024 ROY</p>
      </div>
    </footer>
  );
};

export default Footer;
