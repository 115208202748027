import React, { useEffect, useRef } from "react";
import cytoscape from "cytoscape";
import cise from "cytoscape-cise";
import axios from "axios";
cytoscape.use(cise);
const CytoScape = () => {
  const cyRef = useRef(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(`assets/movies.json`);
        const graphData = response.data;
        const nodes = new Map();
        const edges = new Set();
        const data = graphData.slice(0, 1500);
        data.forEach((element) => {
          element.Actors.toLowerCase()
            .split(", ")
            .forEach((elem) => {
              if (elem.trim()) {
                nodes.set(elem, { data: { id: elem } });
              }
            });
        });
        data.forEach((element) => {
          const elem = element.Actors.toLowerCase().split(", ");
          elem.forEach((source, i) => {
            nodes.set(source, { data: { id: source } });
            for (let j = i + 1; j < elem.length; j++) {
              const target = elem[j];
              if (source !== target) {
                edges.add(
                  JSON.stringify({ source, target })
                );
              }
            }
          });
        });
        const elements = [
          ...nodes.values(),
          ...[...edges].map((edge) => {
            const { source, target } = JSON.parse(edge);
            return { data: { source, target } };
          }),
        ];
        const cy = cytoscape({
          container: cyRef.current,
          elements,
          style: cytoscape
            .stylesheet()
            .selector("node")
            .css({
              content: "data(id)",
              "background-color": "#0074D9",
              label: "data(id)",
              width: "110%",
              height: "100%",
              shape: "roundrectangle",
              "text-background-opacity": 1,
              color: "#fff",
              "text-background-color": "black",
              padding: "5px",
              "text-background-padding": "5px",
              "text-background-shape": "rectangle",
              "text-border-color": "#000",
              "text-border-width": 1,
              "text-border-opacity": 1,
              "text-valign": "center",
              "text-halign": "center",
            })
            .selector("edge")
            .css({
              "curve-style": "bezier",
              "target-arrow-shape": "triangle",
              opacity: 0.5,
            })
            .selector(".highlighted")
            .css({
              "background-color": "#0074D9",
              "line-color": "black",
              "target-arrow-color": "#FF4136",
              "source-arrow-color": "black",
              opacity: 1,
            })
            .selector(".faded")
            .css({
              opacity: 0.1,
              "text-opacity": 0,
            }),
          layout: {
            name: "concentric",
            levelWidth: function (nodes) {
              return nodes.maxDegree() / 15;
            },
            concentric: function (node) {
              return node.degree();
            },
            padding: 0,
          },
        });
        const connectedNodes = cy.nodes().filter((node) => node.connectedEdges().length !== 0);
        const disconnectedNodes = cy.nodes().filter((node) => node.connectedEdges().length === 0);
        disconnectedNodes.remove();
        connectedNodes
          .layout({
            name: "concentric",
            levelWidth: function (nodes) {
              return nodes.maxDegree() / 8;
            },
            concentric: function (node) {
              return node.degree();
            },
            padding: 0,
          })
          .run();

        cy.on("tap", function (event) {
          var evtTarget = event.target;
          if (evtTarget.group && evtTarget.isNode()) {
            cy.elements().removeClass("highlighted");
            cy.elements().addClass("faded");
            const connectedEdges = evtTarget.connectedEdges();
            var connectedNodes = connectedEdges.connectedNodes();
            connectedEdges.removeClass("faded").addClass("highlighted");
            connectedNodes.removeClass("faded").addClass("highlighted");
            connectedNodes
              .layout({
                name: "concentric",
                levelWidth: function (nodes) {
                  return nodes.maxDegree() / 15;
                },
                padding: 20,
              })
              .run();
          } else {
            console.log("else", evtTarget.data("id"));
            cy.elements().removeClass("faded");
            cy.elements().removeClass("highlighted");
            const connectedNodes = cy.nodes().filter((node) => node.connectedEdges().length !== 0);
            connectedNodes
              .layout({
                name: "concentric",
                levelWidth: function (nodes) {
                  return nodes.maxDegree() / 15;
                },
                padding: 0,
              })
              .run();
          }
        });

        return () => {
          cy.destroy();
        };
      } catch (error) {
        console.error("Failed to fetch and render data:", error);
      }
    };
    getData();
  }, []);

  return (
    <div
    ref={cyRef}
    style={{
      width: "90vw",
      height: "90vh",
      border: "1px solid #ccc",
      margin: "auto",
    }}
  ></div>
  );
};

export default CytoScape;
