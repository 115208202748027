import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../../assets/styles/Layout.css";

const AppNavbar = () => {
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("/");
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (eventKey) => {
    setIsOpen(false);
    setActiveKey(eventKey);
  };
  React.useEffect(() => {
    setActiveKey(location.pathname);
  }, [location]); 
  return (
    <Navbar id="navbar-nav">
      <Navbar.Brand as={Link} to="/" >
        <div className="d-flex flex-column align-items-center px-3" style={{ fontSize: "10px" }}>
          <img src="apple-touch-icon.png" height="30px" alt="Logo" />
          <small>Home</small>
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setIsOpen(!isOpen)} />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end  px-3" in={isOpen} onSelect={handleSelect}>
        <Nav activeKey={activeKey}>
          <Nav.Link as={Link} to="/Articles" eventKey="/Articles" onClick={() => setIsOpen(false)}>
            Articles
          </Nav.Link>
          <Nav.Link as={Link} to="/Services" eventKey="/Services" onClick={() => setIsOpen(false)}>
            Music
          </Nav.Link>
          <Nav.Link as={Link} to="/Movies" eventKey="/Movies" onClick={() => setIsOpen(false)}>
            Movies
          </Nav.Link>
          <Nav.Link as={Link} to="/Exercise" eventKey="/Exercise" onClick={() => setIsOpen(false)}>
            Exercise
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavbar;
