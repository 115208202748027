import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Chart } from "react-google-charts";
import { Spotifylogin, getTracks, saveSongs } from "../components/Spotify";
import { useLocation } from "react-router-dom";
import { multiply, invert, transpose } from "../components/Graph";
const ServicesSkills = () => {
  const [collection, setCollection] = useState([]);
  const [selectedObj, setSelectedObj] = useState({});
  const [collectionGenres, setCollectionGenres] = useState([]);
  const [currentGenres, setCurrentGenres] = useState([]);
  const [collectionArtists, setCollectionArtists] = useState([]);
  const [currentArtists, setcurrentArtists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState("");
  const [currentOption, setCurrentOption] = useState(null);
  const [graphOptions, setOptions] = useState();
  const [graphData, setGraphData] = useState();
  const [User, setUser] = useState();
  const audioRefs = React.useRef([]);
  const location = useLocation();
  const PerPage = 30;
  const totalPages = Math.ceil((collection?.length || 0) / PerPage);
  const maxPagesToShow = 3;
  useEffect(() => {
    const GetKey = async (code) => {
      try {
        const response = await axios.post(`/GetKey/${code}`);
        let key = response.data;
        setUser(key); //key.expires_in = Date.now() + 3600 * 1000; localStorage.setItem("userKey", JSON.stringify(key));
      } catch (error) {
        console.error("Error fetching key:", error);
      }
    };
    const code = new URLSearchParams(location.search).get("code");
    if (code) {
      GetKey(code);
    }
  }, [location]);
  useEffect(() => {
    const GetJson = async () => {
      const response = await axios.get(`assets/songs.json`);
      let data = response.data;
      setCollection(data);
      setCollectionGenres([
        ...new Set(
          data
            .flatMap((g) =>
              g.genres
                .toLowerCase()
                .split(",")
                .map((g) => g.replace(",", "").trim())
                .filter((g) => g !== "")
            )
            .sort()
        ),
      ]);
      setCollectionArtists([
        ...new Set(
          data.flatMap((g) =>
            g.artists
              .toLowerCase()
              .split(",")
              .map((g) => g.replace(",", "").trim())
              .filter((g) => g !== "")
          )
        ),
      ]);
    };
    GetJson();
  }, []);
  const getVisiblePages = () => {
    const pages = [];
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
    if (endPage - startPage + 1 < maxPagesToShow && totalPages > maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      setSelectedObj([]);
    }
  };
  const selectItem = (Id) => {
    if (!selectedObj.id) {
      const selected = collection.find((movie) => movie.id === Id);
      playTrack(selected.id);
      setSelectedObj(selected);
    } else if (Id !== selectedObj.id) {
      playTrack(selectedObj.id);
      const selectedTrack = collection.find((movie) => movie.id === Id);
      playTrack(selectedTrack.id);
      setSelectedObj(selectedTrack);
    } else {
      playTrack(selectedObj.id);
      setSelectedObj([]);
    }
  };
  const getValue = (item, key) => {
    if (key === "release_date" || key === "added_at") {
      return new Date(item[key]);
    } else {
      return Number(item[key]) ?? 0;
    }
  };

  const handleSort = (event) => {
    const sortFunctions = {
      duration_ms: (a, b) => getValue(b, "duration_ms") - getValue(a, "duration_ms"),
      popularity: (a, b) => getValue(b, "popularity") - getValue(a, "popularity"),
      danceability: (a, b) => getValue(b, "danceability") - getValue(a, "danceability"),
      energy: (a, b) => getValue(b, "energy") - getValue(a, "energy"),
      valence: (a, b) => getValue(b, "valence") - getValue(a, "valence"),
      loudness: (a, b) => getValue(b, "loudness") - getValue(a, "loudness"),
      speechiness: (a, b) => getValue(b, "speechiness") - getValue(a, "speechiness"),
      liveness: (a, b) => getValue(b, "liveness") - getValue(a, "liveness"),
      tempo: (a, b) => getValue(b, "tempo") - getValue(a, "tempo"),
      release_date: (a, b) => getValue(b, "release_date") - getValue(a, "release_date"),
      date_added: (a, b) => getValue(b, "date_added") - getValue(a, "date_added"),
    };
    let currentList = [...collection];
    const order = event.target.value;
    setCurrentSort(order);
    if (order && sortFunctions[order]) {
      const sorted = [...currentList].sort(sortFunctions[order]);
      setCollection(sorted);
      setSelectedObj([]);
    } else {
      setCollection([...currentList].sort((a, b) => new Date(b.Released) - new Date(a.Released)));
    }
    setCurrentPage(1);
  };
  const handleReverse = () => {
    let currentList = [...collection];
    const sorted = [...currentList].reverse();
    setCollection(sorted);
    setSelectedObj([]);
    setCurrentPage(1);
  };
  const updateGraph = useCallback((items, graphOption) => {
    let Values = [];
    let data = [];
    if (items.length > 0) {
      items
        .sort((a, b) => new Date(b.added_at) - new Date(a.added_at))
        .forEach((item, index) => {
          Values.push([index, getValue(item, graphOption)]);
        });
      let degree = 3;
      let X = Values.map(([x]) => Array.from({ length: degree + 1 }, (_, i) => Math.pow(x, i)));
      let Y = Values.map(([, y]) => [y]);
      let XT = transpose(X);
      let XTX = multiply(XT, X);
      let XTY = multiply(XT, Y);
      let XTXInv = invert(XTX);
      let coefficients = multiply(XTXInv, XTY).map((row) => row[0]);
      Values.forEach(([x, y]) => {
        const Obj = items.at(x);
        var date = new Date(Obj.added_at).toDateString().substring(4, 15).split(" ");
        var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].indexOf(date[0]);
        var day = date[1];
        var year = date[2];
        date = new Date(`${month}/${day}/${year}`);
        var datestr = `${month}/${day}/${year}`;
        let regressionY = coefficients.reduce((sum, coef, i) => sum + coef * Math.pow(x, i), 0);
        data.push([date, y, `${datestr} \n ${Obj.name} \n by ${Obj.artists} \n ${graphOption} : ${y}`, regressionY, ``]);
      });
      data.unshift(["Order", graphOption, { type: "string", role: "tooltip" }, "Polynomial Regression", { type: "string", role: "tooltip" }]);
      var minval = Math.min(...Values.map(([, y]) => y));
      var maxval = Math.max(...Values.map(([, y]) => y)); 
      let options = {
        title: `Polynomial Regression: Song's ${graphOption} By Order`,
        curveType: "function",
        legend: { position: "bottom" },
        hAxis: { title: "Order", viewWindow: { min: new Date(items[0].added_at), max: new Date(items.slice(-1)[0].added_at) } },
        vAxis: { title: graphOption, viewWindow: { min: minval, max: maxval } },
        
      };
      setCurrentOption(graphOption);
      setGraphData(data);
      setOptions(options);
    }
  }, []);
  const handleGenreChange = (item) => {
    item = item.toLowerCase();
    if (item === "") {
      setCurrentGenres([]);
    } else {
      setCurrentGenres((prevSelected) => {
        let updateList;
        if (prevSelected.includes(item)) {
          updateList = prevSelected.filter((g) => g !== item);
        } else {
          updateList = [...prevSelected, item];
        }
        return updateList;
      });
    }
    setCurrentPage(1);
  };
  const handleArtistChange = (item) => {
    item = item.toLowerCase();
    if (item === "") {
      setcurrentArtists([]);
    } else {
      setcurrentArtists((prevSelected) => {
        let updateList;
        if (prevSelected.includes(item)) {
          updateList = prevSelected.filter((g) => g !== item);
        } else {
          updateList = [...prevSelected, item];
        }
        return updateList;
      });
    }
  };
  const handleGraphChange = (event) => {
    var items = [...collection];
    const graphOption = event.target.value;
    updateGraph(items, graphOption);
  };
  const updateCollection = useCallback((items, currentGenres, currentArtists) => {
    if (currentGenres[0]) {
      currentGenres.forEach((a) => {
        items = items.filter((item) => {
          const g1 = item.genres.split(",").map((g) => g.trim().toLowerCase());
          const list = g1.includes(a);
          return list;
        });
      });
    }
    if (currentArtists[0]) {
      currentArtists.forEach((a) => {
        items = items.filter((item) => {
          const g1 = item.artists.split(",").map((g) => g.trim().toLowerCase());
          const list = g1.includes(a);
          return list;
        });
      });
    }
    return items;
  }, []);
  useEffect(() => {
    const sortFunctions = {
      duration_ms: (a, b) => getValue(b, "duration_ms") - getValue(a, "duration_ms"),
      popularity: (a, b) => getValue(b, "popularity") - getValue(a, "popularity"),
      danceability: (a, b) => getValue(b, "danceability") - getValue(a, "danceability"),
      energy: (a, b) => getValue(b, "energy") - getValue(a, "energy"),
      valence: (a, b) => getValue(b, "valence") - getValue(a, "valence"),
      loudness: (a, b) => getValue(b, "loudness") - getValue(a, "loudness"),
      speechiness: (a, b) => getValue(b, "speechiness") - getValue(a, "speechiness"),
      liveness: (a, b) => getValue(b, "liveness") - getValue(a, "liveness"),
      tempo: (a, b) => getValue(b, "tempo") - getValue(a, "tempo"),
      release_date: (a, b) => getValue(b, "release_date") - getValue(a, "release_date"),
      date_added: (a, b) => getValue(b, "date_added") - getValue(a, "date_added"),
    };
    const fetchAndUpdateCollection = async () => {
      try {
        const response = await axios.get(`assets/songs.json`);
        const data = response.data;
        let updatedList = updateCollection(data, currentGenres, currentArtists);
        if (currentSort) {
          updatedList.sort(sortFunctions[currentSort]);
        }
        pauseAllTracks();
        setCollection(updatedList);
      } catch (error) {
        console.error("Error fetching songs JSON:", error);
      }
    };

    fetchAndUpdateCollection();
  }, [currentGenres, currentArtists,currentSort, updateCollection]);
  useEffect(() => {
    const items = [...collection];
    if (currentOption) {
      updateGraph(items, currentOption);
    } else {
      updateGraph(items, "valence");
    }
  }, [collection, currentOption, updateGraph]);
  const handleTrackEnd = () => {
    const currentIndex = collection.findIndex((track) => track.id === selectedObj.id);
    if (currentIndex !== -1) {
      if (currentIndex < collection.length - 1) {
        const nextTrackId = collection[currentIndex + 1].id;
        selectItem(nextTrackId);
      }
    }
  };
  const pauseAllTracks = () => {
    audioRefs.current.forEach(audio => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    });
  };
  const playTrack = (index) => {
    if(audioRefs.current[index]){
      if (index !== selectedObj.id) {
        pauseAllTracks();
        audioRefs.current[index]?.play();
      } else {
        pauseAllTracks();
      }
    }
  
  };
  const goTo = (Link) => {
    window.location.href = Link;
  };
  return (
    <section className="section">
      <div className="px-2">
        <h1 className="text-center">Music</h1>
        <h5 className="text-center">Roy's Liked Songs</h5>
        {!User && (
          <button className="button-54" style={{ position: "absolute", top: "0" }} onClick={Spotifylogin}>
            Login
          </button>
        )}
        <Chart chartType="LineChart" width="100%" height="25vmin" style={{ margin: "0", padding: "0" }} data={graphData} options={graphOptions} legendToggle />
      </div>
      {User && (
        <div className="scroll-banner justify-content-center">
          <button className="button-54" onClick={() => getTracks("long_term")}>
            <span>top </span>~1 year
          </button>
          <button className="button-54" onClick={() => getTracks("medium_term")}>
            <span>top </span>6 months
          </button>
          <button className="button-54" onClick={() => getTracks("short_term")}>
            <span>top </span>4 weeks
          </button>
          <button className="button-54" onClick={() => getTracks()}>
            <span>all </span> Songs
          </button>
          <button className="button-54" onClick={() => saveSongs(collection, currentGenres.length > 0 ? currentGenres.join(" ") : "")}>
            <span>Save to</span> playlist
          </button>
        </div>
      )}
      <div className="scroll-banner justify-content-center">
        <select value={graphOptions ?? "DEFAULT"} aria-label="Order Selection" className="button-54" onChange={(event) => handleGraphChange(event)}>
          <option value="DEFAULT" disabled>
            Choose a y-axis ...
          </option>
          <option value="valence">valence</option>
          <option value="danceability">danceability</option>
          <option value="energy">energy</option>
          <option value="popularity">popularity</option>
        </select>
        <select value={currentSort ?? "DEFAULT"} aria-label="Order Selection" className="button-54" onChange={(event) => handleSort(event)}>
          <option value="DEFAULT" disabled>
            Choose sorting order ...
          </option>
          <option value="added_at">Added At</option>
          <option value="valence">valence</option>
          <option value="danceability">danceability</option>
          <option value="energy">energy</option>
          <option value="popularity">popularity</option>
        </select>
        <button aria-label="Toggle Asc/Desc" className="button-54" onClick={() => handleReverse()}>
          <svg width="24px" height="24px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000" strokeWidth="0.00024000000000000003">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.528"></g>
            <g id="SVGRepo_iconCarrier">
              <path d="M9 8.24994C8.81 8.24994 8.62 8.17994 8.47 8.02994L6.5 6.05994L4.53 8.02994C4.24 8.31994 3.76 8.31994 3.47 8.02994C3.18 7.73994 3.18 7.25994 3.47 6.96994L5.97 4.46994C6.26 4.17994 6.74 4.17994 7.03 4.46994L9.53 6.96994C9.82 7.25994 9.82 7.73994 9.53 8.02994C9.38 8.17994 9.19 8.24994 9 8.24994Z" fill="#000000"></path>{" "}
              <path d="M6.5 19.75C6.09 19.75 5.75 19.41 5.75 19V5C5.75 4.59 6.09 4.25 6.5 4.25C6.91 4.25 7.25 4.59 7.25 5V19C7.25 19.41 6.91 19.75 6.5 19.75Z" fill="#000000"></path> <path d="M20 17.25H12C11.59 17.25 11.25 16.91 11.25 16.5C11.25 16.09 11.59 15.75 12 15.75H20C20.41 15.75 20.75 16.09 20.75 16.5C20.75 16.91 20.41 17.25 20 17.25Z" fill="#000000"></path>{" "}
              <path d="M16 11.25H12C11.59 11.25 11.25 10.91 11.25 10.5C11.25 10.09 11.59 9.75 12 9.75H16C16.41 9.75 16.75 10.09 16.75 10.5C16.75 10.91 16.41 11.25 16 11.25Z" fill="#000000"></path> <path d="M14 8.25H12C11.59 8.25 11.25 7.91 11.25 7.5C11.25 7.09 11.59 6.75 12 6.75H14C14.41 6.75 14.75 7.09 14.75 7.5C14.75 7.91 14.41 8.25 14 8.25Z" fill="#000000"></path>{" "}
              <path d="M18 14.25H12C11.59 14.25 11.25 13.91 11.25 13.5C11.25 13.09 11.59 12.75 12 12.75H18C18.41 12.75 18.75 13.09 18.75 13.5C18.75 13.91 18.41 14.25 18 14.25Z" fill="#000000"></path>
            </g>
          </svg>
        </button>
        <button className="button-54" onClick={() => goTo("/CytoScape_M")}>
          CytoScape
        </button>
      </div>
      <div className="scroll-banner justify-content-center">
        <select value={currentGenres.at(-1) ?? "DEFAULT"} aria-label="Order Selection" className="button-54" onChange={(event) => handleGenreChange(event.target.value)}>
          <option value="DEFAULT" disabled>
            Choose a genre ...
          </option>
          {collectionGenres
            .filter((a) => collection.some((b) => b.genres.toLowerCase().split(",").includes(a.toLowerCase())))
            .map((g) => {
              return (
                <option key={g} value={g}>
                  {g}
                </option>
              );
            })}
        </select>
        <select value={currentArtists[0] ?? "DEFAULT"} aria-label="Order Selection" className="button-54" onChange={(event) => handleArtistChange(event.target.value)}>
          <option value="DEFAULT" disabled>
            Choose a artist ...
          </option>
          {collectionArtists
            .filter((a) =>
              collection.some((b) =>
                b.artists
                  .split(",")
                  .map((g) => g.trim().toLowerCase())
                  .includes(a.toLowerCase())
              )
            )
            .map((g) => {
              return (
                <option key={g} value={g}>
                  {g}
                </option>
              );
            })}
        </select>
      </div>
      <nav style={{ width: "30%" }}>
        <ul style={{ overflowX: "hidden" }}>
          {(currentArtists[0] || currentGenres[0]) && (
            <li role="button" className="button-54" onClick={() => handleGenreChange("") + handleArtistChange("")}>
              <div className="section-item">
                <div>
                  <h5>Remove All Filters</h5>
                </div>
              </div>
            </li>
          )}
          {collectionGenres
            .filter((a) => currentGenres.includes(a.toLowerCase()))
            .map((g) => (
              <li key={g} role="button" className="button-54" onClick={() => handleGenreChange(g)}>
                <div className="section-item">
                  <h5>Remove {g}</h5>
                </div>
              </li>
            ))}
          {collectionArtists
            .filter((a) => currentArtists.includes(a.toLowerCase()))
            .map((g) => (
              <li key={g} role="button" className="button-54" onClick={() => handleArtistChange(g)}>
                <div className="section-item">
                  <h5>Remove {g}</h5>
                </div>
              </li>
            ))}
          <li>
            <div>
              <h5>{collection.length}&nbsp;Songs</h5>
            </div>
          </li>
          {collection.slice((currentPage - 1) * PerPage, (currentPage - 1) * PerPage + PerPage).map((item) => (
            <li role="button" key={item.id} className={selectedObj.id === item.id ? "button-54 active1" : "button-54"} onClick={() => selectItem(item.id)}>
              <div className="section-item">
                <img src={item.image} alt={`${item.name} track cover`} />
                <div>
                  <h5>{item.name}</h5>
                  <h6>{item.artists}</h6>
                </div>
              </div>
              <audio ref={(el) => (audioRefs.current[item.id] = el)} onEnded={handleTrackEnd}>
                <source src={item.preview_url} type="audio/mpeg" />
              </audio>
            </li>
          ))}
        </ul>
      </nav>
      <article style={{ width: "70%" }}>
        {selectedObj.name ? (
          <>
            <h2>{selectedObj.name}</h2>
            <h3>{selectedObj.added_at}</h3>
            <p className="breadcrumb">
              Artist:&nbsp;
              {selectedObj.artists.toUpperCase().split(",").map((a) => (
                <small key={a} className="breadcrumb-item" onClick={() => handleArtistChange(a)}>
                  {a}
                </small>
              ))}
            </p>
            <p className="breadcrumb">
              Genres:&nbsp;
              {[...new Set(selectedObj.genres.toUpperCase().split(","))].map((a) => (
                <small key={a} className="breadcrumb-item" onClick={() => handleGenreChange(a)}>
                  {a}
                </small>
              ))}
            </p>
           <table className="table table-bordered">
              <tbody>
                {Object.entries(selectedObj).map(([key, value]) => {
                  if (key !== "added_at" && key !== "duration_ms" && key !== "id" && key !== "preview_url" && key !== "image" && key !== "link" && key !== "artistsIDs" && key !== "genres" && key !== "userId" && key !== "artists" && key !== "name") {
                    if (key === "duration_ms") {
                      value = `${Math.floor(selectedObj.duration_ms / 60000)}:${((selectedObj.duration_ms % 60000) / 1000).toFixed(0)}`;
                    }
                    return (
                      <tr key={key}>
                        <th className="py-1">{key}</th>
                        <td className="py-1" align="left">
                          {value}
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
                <tr align="center">
                  <td colSpan="2">
                    <a href={selectedObj.link} target="_blank" rel="noopener noreferrer">
                      Spotify
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <>
            <h2>Select A Song</h2>
            <h3>See its details</h3>
            <p>Danceability: Danceability describes how suitable a track is for dancing based on a combination of musical elements including tempo, rhythm stability, beat strength, and overall regularity. A value of 0.0 is least danceable and 1.0 is most danceable.</p>
            <p>Energy: Energy is a measure from 0.0 to 1.0 and represents a perceptual measure of intensity and activity. Typically, energetic tracks feel fast, loud, and noisy.</p>
            <p>Valence: A measure from 0.0 to 1.0 describing the musical positiveness conveyed by a track. Tracks with high valence sound more positive (e.g. happy, cheerful, euphoric), while tracks with low valence sound more negative (e.g. sad, depressed, angry).</p>
            <p>popularity: The value will be between 0 and 100, with 100 being the most popular. The popularity of a track is a value between 0 and 100, with 100 being the most popular. The popularity is calculated by algorithm and is based, in the most part, on the total number of plays the track has had and how recent those plays are.</p>
          </>
        )}
      </article>
      <div className="dock-bottom">
        <div className="scroll-banner justify-content-center">
          <button className="button-54" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Prev
          </button>
          {currentPage > Math.ceil(maxPagesToShow / 2) && (
            <button className="button-54" onClick={() => handlePageChange(1)}>
              1
            </button>
          )}
          {currentPage > Math.ceil(maxPagesToShow / 2) && <span>...</span>}
          {getVisiblePages().map((page) => (
            <button key={page} onClick={() => handlePageChange(page)} className={page === currentPage ? "button-54 active1" : "button-54"}>
              {page}
            </button>
          ))}
          {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && <span>...</span>}
          {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && (
            <button className="button-54" onClick={() => handlePageChange(totalPages)}>
              {totalPages}
            </button>
          )}
          <button className="button-54" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </section>
  );
};
export default ServicesSkills;
