import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import 'fomantic-ui-css/semantic.css';
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Music from "./pages/Music";
import Movies from "./pages/Movies";
import Exercise from "./pages/Excercise";
import CytoScape from "./components/CytoScape";
import CytoScapem from "./components/CytoScape_M";
import CytoScapeGen from "./components/CytoScapeGen";

const AppContent = () => {
  const [activePath, setActivePath] = useState("/Home");
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    setActivePath(page === "/" ? "Home" : page.replace("/", ""));
  }, [location]);

  return (
    <Layout>
      <HelmetProvider>
        <Helmet>
          <title>{activePath}</title>
          <meta property="og:title" content={activePath} />
          <meta property="og:url" content={`https://royclaudio.com${activePath}`} />
          <meta property="og:description" content="Roy" />
          <meta property="og:site_name" content="RC" />
          <meta property="og:image:alt" content="RoyClaudio.com logo" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:image" content="assets/RC1.png" />
          <meta property="og:image:width" content="1200px" />
          <meta property="og:image:height" content="628px" />
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Articles" element={<Blog />} />
          <Route path="/Services" element={<Music />} />
          <Route path="/Movies" element={<Movies />} />
          <Route path="/CytoScape" element={<CytoScape />} />
          <Route path="/CytoScape_M" element={<CytoScapem />} />
          <Route path="/CytoScapeGen" element={<CytoScapeGen />} />
          <Route path="/Exercise" element={<Exercise />} />
        </Routes>
      </HelmetProvider>
    </Layout>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
