import React, { useState, useEffect, useRef, useCallback } from "react";
import "../assets/styles/Home.css";
const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const totalSlides = 4;
  const sliderRef = useRef(null);
  const startX = useRef(0);
  const startY = useRef(0);
  const isDragging = useRef(false);
  const currentTranslateX = useRef(0);
  useEffect(() => {
    const handleResize = debounce(() => setWindowWidth(window.innerWidth), 200);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };
  const sliderStyle = {
    transform: `translate3d(-${currentIndex * windowWidth}px, 0, 0)`,
    transition: "transform 500ms ease",
    width: `${windowWidth * totalSlides}px`,
  };
  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) => {
      return prevIndex > 0 ? prevIndex - 1 : totalSlides - 1;
    });
  }, [totalSlides]);

  const handlePrev = useCallback(() => {
    setCurrentIndex((prevIndex) => {
      return prevIndex < totalSlides - 1 ? prevIndex + 1 : 0;
    });
  }, [totalSlides]);

  const handleDotClick = useCallback((index) => {
    setCurrentIndex(index);
    if (sliderRef.current) {
      sliderRef.current.scrollTo({ left: index * sliderRef.current.offsetWidth, behavior: "smooth" });
    }
  }, []);
  useEffect(() => {
    const interval = setInterval(handleNext, 30000);
    return () => clearInterval(interval);
  }, [handleNext, handlePrev]);

  const goTo = (Link) => {
    window.location.href = Link;
  };

  useEffect(() => {
    const moveDrag = (clientX, clientY) => {
      if (!isDragging.current) return;
      const moveX = clientX - startX.current;
      const moveY = clientY - startY.current;
      if (Math.abs(moveY) > Math.abs(moveX)) {
        isDragging.current = false;
      }
      if (isDragging.current) {
        const newTranslateX = currentTranslateX.current + moveX;
        sliderRef.current.style.transform = `translate3d(${newTranslateX}px, 0, 0)`;
      }
    };
    const startDrag = (clientX, clientY, event) => {
      if (!event.touches) {
        event.preventDefault();
      }
      startX.current = clientX;
      startY.current = clientY;
      currentTranslateX.current = -currentIndex * windowWidth;
      isDragging.current = true;
    };
    const endDrag = () => {
      if (!isDragging.current) return;
      isDragging.current = false;
      const moveDistance = parseInt(sliderRef.current.style.transform.split("(")[1]) + currentIndex * windowWidth;
      if (moveDistance > windowWidth / 2) {
        handleNext();
      } else if (moveDistance < -windowWidth / 2) {
        handlePrev();
      }
      sliderRef.current.style.transform = `translate3d(-${currentIndex * windowWidth}px, 0, 0)`;
    };
    const slider = sliderRef.current;
    const handleTouchStart = (e) => startDrag(e.touches[0].clientX, e.touches[0].clientY, e);
    const handleMouseDown = (e) => startDrag(e.clientX, e.clientX, e);
    const handleTouchMove = (e) => moveDrag(e.touches[0].clientX, e.touches[0].clientY);
    const handleMouseMove = (e) => moveDrag(e.clientX, e.clientX);
    const handleTouchEnd = endDrag;
    const handleMouseUp = endDrag;
    if (slider) {
      slider.addEventListener("touchstart", handleTouchStart, { passive: true });
      slider.addEventListener("touchmove", handleTouchMove, { passive: true });
      slider.addEventListener("touchend", handleTouchEnd, { passive: true });
      slider.addEventListener("mousedown", handleMouseDown);
      slider.addEventListener("mousemove", handleMouseMove);
      slider.addEventListener("mouseup", handleMouseUp);
      slider.addEventListener("mouseleave", handleMouseUp);
      return () => {
        slider.removeEventListener("touchstart", handleTouchStart);
        slider.removeEventListener("touchmove", handleTouchMove);
        slider.removeEventListener("touchend", handleTouchEnd);
        slider.removeEventListener("mousedown", handleMouseDown);
        slider.removeEventListener("mousemove", handleMouseMove);
        slider.removeEventListener("mouseup", handleMouseUp);
        slider.removeEventListener("mouseleave", handleMouseUp);
      };
    }
  }, [currentIndex, handlePrev, windowWidth, handleNext]);

  return (
    <>
      <div className="marquee">
        <div role="button" className="marquee-prev" onClick={handlePrev}><i class="chevron right icon"></i></div>
        <div className="marquee-slider" role="button" style={sliderStyle} ref={sliderRef}>
          <div className="marquee-wrapper" style={{ width: `${windowWidth}px` }} onClick={() => goTo("/Exercise")}>
            <img src="assets/marquee/Sky3.jpg" alt="Sky 3" />
            <div className="marquee-text">
              <h1>Exercise Today!</h1>
              <p>Find your next workout from a collection of over 800 exercises.</p>
            </div>
          </div>
          <div className="marquee-wrapper" style={{ width: `${windowWidth}px` }} onClick={() => goTo("/Movies")}>
            <img src="assets/marquee/Sky1.jpg" alt="Sky 1" />
            <div className="marquee-text">
              <h1>Find Your next watch today!</h1>
              <p>explore over 8 thousand movies.</p>
            </div>
          </div>
          <div className="marquee-wrapper" style={{ width: `${windowWidth}px` }} onClick={() => goTo("/Services")}>
            <img src="assets/marquee/Sky2.jpg" alt="Sky 2" />
            <div className="marquee-text">
              <h1>Music Collection.</h1>
              <p>the music database lets users sort and explore my music by genre and audio features.</p>
            </div>
          </div>
          <div className="marquee-wrapper" style={{ width: `${windowWidth}px` }} onClick={() => goTo("/Articles")}>
            <img src="assets/marquee/Sky3.jpg" alt="Sky 3" />
            <div className="marquee-text">
              <h1>Visit my blog page</h1>
              <p>Explore various concepts and maybe learn something new.</p>
            </div>
          </div>
        </div>
        <div role="button" className="marquee-next" onClick={handleNext}><i class="chevron left icon"></i></div>

        <ul className="marquee-dots">
          {[...Array(totalSlides)].map((_, index) => (
            <li key={index}>
              <button onClick={() => handleDotClick(index)} className={`${index === currentIndex ? "dots-active" : ""}`}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
export default Home;
