import React from "react";
import {
  TwitterShareButton,
  LinkedinShareButton,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import "../assets/styles/Blog.css";

const ShareButtons = ({ url, title, description, image }) => {
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("Link copied to clipboard! " + url);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <>
      <div className="share-buttons-container">
        <div className="share-buttons">
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <LinkedinShareButton url={url} title={title} summary={description}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <button
            onClick={handleCopyClick}
            className="button-55">
           🔗
          </button>
        </div>
      </div>
    </>
  );
};

export default ShareButtons;
