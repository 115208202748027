import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Chart } from "react-google-charts";
import RatingComponent from "../components/RatingComponent";
import { multiply, invert, transpose } from "../components/Graph";
import "../assets/styles/Movies.css";
import { Dropdown } from "semantic-ui-react";

const Movies = () => {
  const [collection, setCollection] = useState([]);
  const [selectedObj, setSelectedObj] = useState({});
  const [collectionGenres, setCollectionGenres] = useState([]);
  const [currentGenres, setCurrentGenres] = useState([]);
  const [collectionDirector, setCollectionDirectors] = useState([]);
  const [currentDirector, setcurrentDirectors] = useState([]);
  const [collectionActor, setCollectionActor] = useState([]);
  const [currentActor, setcurrentActor] = useState([]);
  const [collectionSearchResults, setCollectionSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOption, setsearchOption] = useState({});
  const [currentSort, setCurrentSort] = useState();
  const sortCollection = ["imdbRating", "Runtime", "Awards", "imdbVotes", "Released", "Genre", "Box_office", "Metascore"];
  const [collectionRated, setCollectionRated] = useState([]);
  const [currentRating, setCurrentRating] = useState([]);
  const [currentOption, setCurrentOption] = useState(null);
  const [graphOptions, setOptions] = useState();
  const [graphData, setGraphData] = useState();
  const PerPage = 30;
  const totalPages = Math.ceil((collection?.length || 0) / PerPage);
  const maxPagesToShow = 3;
  useEffect(() => {
    const GetJson = async () => {
      const response = await axios.get(`assets/movies.json`);
      let data = response.data;
      setCollection(data);
      setCollectionGenres([
        ...new Set(
          data.flatMap((movie) =>
            movie.Genre.toUpperCase()
              .split(", ")
              .map((g) => g.trim())
          )
        ),
      ]);
      setCollectionRated([...new Set(data.map((g) => g.Rated.toUpperCase().trim()))]);
      setCollectionDirectors([...new Set(data.sort((a, b) => b.Box_office - a.Box_office).flatMap((g) => g.Director.toUpperCase().split(", ")))]);
      setCollectionActor([...new Set(data.sort((a, b) => b.Box_office - a.Box_office).flatMap((g) => g.Actors.toUpperCase().split(", ")))]);
    };
    GetJson();
  }, []);
  const getVisiblePages = () => {
    const pages = [];
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
    if (endPage - startPage + 1 < maxPagesToShow && totalPages > maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      window.scrollTo(0, 0);
      setSelectedObj([]);
    }
  };
  const selectItem = (Id) => {
    if (!selectedObj.id) {
      const selected = collection.find((movie) => movie.id === Id);
      setSelectedObj(selected);
    } else if (Id !== selectedObj.id) {
      const selected = collection.find((movie) => movie.id === Id);
      setSelectedObj(selected);
    } else {
      setSelectedObj([]);
    }
    window.scrollTo(0, 0);
  };
  const getValue = (item, key) => {
    if (key === "Released") {
      return new Date(item[key]);
    } else if (key === "Box_office" || key === "imdbVotes") {
      return Number(item[key]?.replace(/,/g, "") || 0);
    } else if (key === "Runtime") {
      return Number(item[key]?.replace(/ MIN$/i, "") || 0);
    } else if (key === "Awards") {
      const numbers = item[key].match(/\d+(\.\d+)?/g) || [];
      const sum = numbers.map(Number).reduce((acc, num) => acc + num, 0);
      return sum;
    } else {
      return ["null", "N/A"].includes(item[key]) ? 0 : Number(item[key]);
    }
  };
  const handleSort = (order) => {
    let currentList = [...collection];
    const sortFunctions = {
      imdbRating: (a, b) => getValue(b, "imdbRating") - getValue(a, "imdbRating"),
      Runtime: (a, b) => getValue(b, "Runtime") - getValue(a, "Runtime"),
      Awards: (a, b) => getValue(b, "Awards") - getValue(a, "Awards"),
      imdbVotes: (a, b) => getValue(b, "imdbVotes") - getValue(a, "imdbVotes"),
      Released: (a, b) => getValue(b, "Released") - getValue(a, "Released"),
      Genre: (a, b) => getValue(b, "Genre") - getValue(a, "Genre"),
      Box_office: (a, b) => getValue(b, "Box_office") - getValue(a, "Box_office"),
      Metascore: (a, b) => getValue(b, "Metascore") - getValue(a, "Metascore"),
    };
    if (order && sortFunctions[order]) {
      const sorted = [...currentList].sort(sortFunctions[order]);
      setCollection(sorted);
      setSelectedObj([]);
      setCurrentSort(order);
    } else {
      setCollection([...currentList].sort((a, b) => new Date(b.Released) - new Date(a.Released)));
    }
    window.scrollTo(0, 0);
    setCurrentPage(1);
  };
  const handleReverse = () => {
    let currentList = [...collection];
    const sorted = [...currentList].reverse();
    setCollection(sorted);
    setSelectedObj([]);
    window.scrollTo(0, 0);
    setCurrentPage(1);
  };
  const updateGraph = useCallback((items, graphOption) => {
    let Values = [];
    let data = [];
    if (items.length > 0) {
      items
        .sort((a, b) => new Date(b.Released) - new Date(a.Released))
        .forEach((item, index) => {
          Values.push([index, getValue(item, graphOption)]);
        });
      let degree = 3;
      let X = Values.map(([x]) => Array.from({ length: degree + 1 }, (_, i) => Math.pow(x, i)));
      let Y = Values.map(([, y]) => [y]);
      let XT = transpose(X);
      let XTX = multiply(XT, X);
      let XTY = multiply(XT, Y);
      let XTXInv = invert(XTX);
      let coefficients = multiply(XTXInv, XTY).map((row) => row[0]);
      Values.forEach(([x, y], idx) => {
        const Obj = items.at(idx);
        var date = new Date(Obj.Released).toDateString().substring(4, 15).split(" ");
        var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].indexOf(date[0]);
        var day = date[1];
        var year = date[2];
        date = new Date(`${month}/${day}/${year}`);
        let regressionY = coefficients.reduce((sum, coef, i) => sum + coef * Math.pow(x, i), 0);

        data.push([date, y, ``, regressionY, ``]);
      });
      data.unshift(["Order", graphOption, { type: "string", role: "tooltip" }, "Polynomial Regression", { type: "string", role: "tooltip" }]);
      var minval = Math.min(...Values.map((a) => a[1]));
      var maxval = Math.max(...Values.map((a) => a[1]));
      let options = {
        title: `Polynomial Regression: Movie's ${graphOption} By Order`,
        curveType: "function",
        legend: { position: "bottom" },
        vAxis: { title: graphOption, viewWindow: { min: minval, max: maxval } },
        hAxis: { title: "Order", viewWindow: { min: new Date(1979, 1, 1), max: new Date(2016, 1, 1) } },
      };
      setCurrentOption(graphOption);
      setGraphData(data);
      setOptions(options);
    }
  }, []);
  const handleGraphChange = (value) => {
    var items = [...collection];
    const graphOption = value;
    updateGraph(items, graphOption);
  };
  const handleDirectorChange = (item) => {
    item = item.toUpperCase();
    if (item === "") {
      setcurrentDirectors([]);
    } else {
      setcurrentDirectors((prevSelected) => {
        let updateList;
        if (prevSelected.includes(item)) {
          updateList = prevSelected.filter((g) => g !== item);
        } else {
          updateList = [...prevSelected, item];
        }
        return updateList;
      });
    }
  };
  const handleActorChange = (item) => {
    item = item.toUpperCase();
    if (item === "") {
      setcurrentActor([]);
    } else {
      setcurrentActor((prevSelected) => {
        let updateList;
        if (prevSelected.includes(item)) {
          updateList = prevSelected.filter((g) => g !== item);
        } else {
          updateList = [...prevSelected, item];
        }
        return updateList;
      });
    }
  };
  const handleGenreChange = (item) => {
    item = item.toUpperCase();
    if (item === "") {
      setCurrentGenres([]);
    } else {
      setCurrentGenres((prevSelected) => {
        let updateList;

        if (prevSelected.includes(item)) {
          updateList = prevSelected.filter((g) => g !== item);
        } else {
          updateList = [...prevSelected, item];
        }
        return updateList;
      });
    }
  };
  const HandleRatingChange = (item) => {
    item = item.toUpperCase();
    if (item === "") {
      setCurrentRating([]);
    } else {
      setCurrentRating((prevSelected) => {
        let updateList;
        if (prevSelected.includes(item)) {
          updateList = prevSelected.filter((g) => g !== item);
        } else {
          updateList = [...prevSelected, item];
        }
        return updateList;
      });
    }
  };
  const updateCollection = useCallback((items, currentRating, currentGenres, currentDirector, currentActor) => {
    if (currentDirector[0]) {
      items = items.filter((movie) => {
        const check = movie.Director.toUpperCase().split(", ");
        const list = currentDirector.some((a) => check.includes(a));
        return list;
      });
    }
    if (currentActor[0]) {
      items = items.filter((movie) => {
        const check = movie.Actors.toUpperCase().split(", ");
        const list = currentActor.some((a) => check.includes(a));
        return list;
      });
    }
    if (currentRating[0]) {
      currentGenres.forEach((a) => {
        items = items.filter((movie) => {
          const rating = movie.Rated.toUpperCase();
          const list = currentRating.some((a) => a.toUpperCase() === rating);
          const g1 = movie.Genre.split(", ").map((g) => g.trim().toUpperCase());
          const list1 = g1.includes(a);
          return list && list1;
        });
      });
    }
    if (currentGenres[0] && !currentRating[0]) {
      currentGenres.forEach((a) => {
        items = items.filter((movie) => {
          const g1 = movie.Genre.split(", ").map((g) => g.trim().toUpperCase());
          const list = g1.includes(a);
          return list;
        });
      });
    }
    if (currentRating[0] && !currentGenres[0]) {
      items = items.filter((movie) => {
        const rating = movie.Rated.toUpperCase();
        const list = currentRating.some((a) => a.toUpperCase() === rating);
        return list;
      });
    }
    return items;
  }, []);
  const SearchResults = React.useMemo(() => {
    var input = document.getElementById("SearchInput");
    return (
      <Dropdown
        placeholder="Results..."
        multiple
        selection
        options={collectionSearchResults.map((b) => {
          return {
            text: b,
            value: b,
            key: b,
            onClick: () => {
              if (searchOption.option === "Actor") {
                handleActorChange(b);
              } else if (searchOption.option === "Director") {
                handleDirectorChange(b);
              } else {
                const selectedObj = collectionSearchResults.find((g) => g.id === b);
                setSelectedObj(selectedObj);
              }
              setsearchOption({});
              setCollectionSearchResults([]);
              input.value = "";
            },
          };
        })}
      />
    );
  }, [searchOption, collectionSearchResults]);
  useEffect(() => {
    const sortFunctions = {
      imdbRating: (a, b) => getValue(b, "imdbRating") - getValue(a, "imdbRating"),
      Runtime: (a, b) => getValue(b, "Runtime") - getValue(a, "Runtime"),
      Awards: (a, b) => getValue(b, "Awards") - getValue(a, "Awards"),
      imdbVotes: (a, b) => getValue(b, "imdbVotes") - getValue(a, "imdbVotes"),
      Released: (a, b) => getValue(b, "Released") - getValue(a, "Released"),
      Genre: (a, b) => getValue(b, "Genre") - getValue(a, "Genre"),
      Box_office: (a, b) => getValue(b, "Box_office") - getValue(a, "Box_office"),
      Metascore: (a, b) => getValue(b, "Metascore") - getValue(a, "Metascore"),
    };
    const fetchAndUpdateCollection = async () => {
      try {
        const response = await axios.get(`assets/movies.json`);
        const data = response.data;
        let updatedList = updateCollection(data, currentRating, currentGenres, currentDirector, currentActor);
        if (currentSort) {
          updatedList.sort(sortFunctions[currentSort]);
        }
        setCollection(updatedList);
      } catch (error) {
        console.error("Error fetching songs JSON:", error);
      }
    };
    fetchAndUpdateCollection();
  }, [currentRating, currentGenres, currentDirector, currentActor, currentSort, updateCollection]);

  useEffect(() => {
    const items = [...collection].filter((a) => a.Released !== "N/A");
    if (currentOption) {
      updateGraph(items, currentOption);
    } else {
      updateGraph(items, "Box_office");
    }
  }, [collection, currentOption, updateGraph]);
  function timeSince(createdAt) {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const seconds = Math.floor((now - createdDate) / 1000);
    const intervals = {
      years: 31536000,
      months: 2592000,
      weeks: 604800,
      days: 86400,
      hours: 3600,
      minutes: 60,
      seconds: 1,
    };

    let timeString = "";

    for (const interval in intervals) {
      const diff = Math.floor(seconds / intervals[interval]);
      if (diff >= 1) {
        timeString = `${diff} ${interval} ago`;
        break;
      }
    }

    return timeString || "just now";
  }
  function searchFunction(option, query) {
    const filter = query.toUpperCase();
    const list = [...collection];

    if (query === "") {
      setsearchOption({});
      setCollectionSearchResults([]);
      return;
    }

    let results = [];
    switch (option) {
      case "Actor":
        results = collectionActor.filter((actor) => actor.toUpperCase().includes(filter));
        break;
      case "Director":
        results = collectionDirector.filter((director) => director.toUpperCase().includes(filter));
        break;
      case "Title":
        results = list.filter((movie) => movie.Title.toUpperCase().includes(filter));
        results.sort((a, b) => a.Title.localeCompare(b.Title));
        break;
      default:
        return;
    }
    setsearchOption({ option, query });
    setCollectionSearchResults(results.sort());
  }
  const goTo = (Link) => {
    window.location.href = Link;
  };
  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        var obj = chartWrapper.getChart().getSelection();
        if (obj[0]) {
          console.log("Selected ", obj[0].row, obj[0].column);
        }
      },
    },
  ];
  function OpenTab(id) {
    const collapseElement = document.getElementById(`${id}-tab-pane`);
    const buttonElement = document.getElementById(`${id}-tab`);
    const myTab = document.getElementById(`myTab`);
    const myTabContent = document.getElementById(`myTabContent`);
    if (collapseElement.classList.contains("show")) {
      collapseElement.classList.remove("show");
      collapseElement.classList.remove("active");
      buttonElement.classList.remove("active");
    } else {
      const childrenTabs = myTab.querySelectorAll("*");
      const childrenTabContent = myTabContent.querySelectorAll("*");
      childrenTabs.forEach((child) => {
        child.classList.remove("show");
        child.classList.remove("active");
      });
      childrenTabContent.forEach((child) => {
        child.classList.remove("show");
        child.classList.remove("active");
      });
      collapseElement.classList.add("show");
      collapseElement.classList.add("active");
      buttonElement.classList.add("active");
    }
  }
  return (
    <section className="container">
      <div className="ui top attached tabular menu" id="myTab">
        <button className="item active" onClick={() => OpenTab("overview")} id="overview-tab">
          Movies
        </button>
        <button className="item" onClick={() => OpenTab("stats")} id="stats-tab">
          Movie Statistics
        </button>
      </div>
      <div className="tab-content ui bottom attached active tab segment" id="myTabContent">
        <div className="tab-pane fade show active" id="overview-tab-pane">
          <div className="divider"></div>
          <div className="ui stackable aligned grid">
            <div className="center aligned one column row">
              <div className="column">
                <h1 className="text-center">Movies</h1>
                <h5 className="text-center">1980 - 2015</h5>
              </div>
              <div className="column">
                <div className="ui input">
                  <input type="search" id="SearchInput" placeholder="Search..." onChange={(event) => searchFunction("Actor", event.target.value)} />
                </div>
                {/* {searchOption.option && (
                  <Dropdown
                    placeholder="Options"
                    multiple
                    selection
                    options={["Actor", "Director", "Title"].map((b) => {
                      return { text: b, value: b, key: b, onClick: () => searchFunction(b, searchOption.query) };
                    })}
                  />
                )} */}
              </div>
              <div className="column d-flex flex-row align-items-center justify-content-center">
                <Dropdown
                  placeholder="Sort"
                  multiple
                  selection style={{margin: "10px"}}
                  options={sortCollection.map((b) => {
                    return { text: b, value: b, key: b, onClick: () => handleSort(b) };
                  })}
                />
                <button aria-label="Toggle Asc/Desc" key="Reverse" id="rocket" className="ui icon button" onClick={() => handleReverse()}>
                  <i className={`sort amount up alternate icon`}></i>
                </button>
                <Dropdown
                  placeholder="Actors"
                  multiple
                  selection
                  options={collectionRated.map((b) => {
                    return { text: b, value: b, key: b, onClick: () => HandleRatingChange(b) };
                  })}
                />
                <Dropdown
                  placeholder="Genre"
                  multiple
                  selection
                  options={collectionGenres.map((b) => {
                    return { text: b, value: b, key: b, onClick: () => handleGenreChange(b) };
                  })}
                />
                <button className="ui black icon button" onClick={() => goTo("/CytoScape")}>
                  🌀
                </button>
              </div>
              <div className="column">{collectionSearchResults.length > 0 && SearchResults}</div>
            </div>
          </div>
          {selectedObj.Title && (
            <>
              <div className="movie-container">
                <p className="breadcrumb">
                  <small className="breadcrumb-item" onClick={() => selectItem(selectedObj.id)}>
                    List
                  </small>
                  <small className="breadcrumb-item">{selectedObj.Title}</small>
                </p>
                <img className="movie-poster" src={`assets/Movie_Poster_Dataset/${selectedObj.Released.split(" ")[2]}/${selectedObj.imdbID}.jpg`} alt={`${selectedObj.Title} movie Poster`} />

                <div className="movie-details">
                  <h2 className="movie-title">
                    {selectedObj.Title} - {selectedObj.Rated}
                  </h2>
                  <div className="movie-rating">
                    {Array(Math.floor(((Number(selectedObj.imdbRating) * 10) / 100) * 5)).fill("★")}
                    {Array(5 - Math.floor(((Number(selectedObj.imdbRating) * 10) / 100) * 5)).fill("☆")}
                  </div>
                  <h3 className="movie-release-date">{selectedObj.Released.split(" ")[1] + " " + selectedObj.Released.split(" ")[0] + " " + selectedObj.Released.split(" ")[2]}</h3>
                  <div className="movie-director">
                    Directed by:&nbsp;
                    {selectedObj.Director.split(", ").map((a) => (
                      <small key={a} className="director-name" onClick={() => handleDirectorChange(a)}>
                        {a}
                      </small>
                    ))}
                  </div>
                  <div className="movie-actors">
                    Actors:&nbsp;
                    {selectedObj.Actors.split(", ").map((a) => (
                      <small key={a} className="actor-name" onClick={() => handleActorChange(a)}>
                        {a}
                      </small>
                    ))}
                  </div>
                  <p className="movie-writers">Writers: {selectedObj.Writer}</p>
                  <div className="movie-info">
                    <div className="info-item">
                      <span className="info-title">Runtime:&nbsp;</span>
                      <span className="info-value">{selectedObj.Runtime}</span>
                    </div>
                    <div className="info-item">
                      <span className="info-title">Box Office:&nbsp;</span>
                      <span className="info-value">{selectedObj.Box_office}</span>
                    </div>
                    <div className="info-item">
                      <span className="info-title">Awards:&nbsp;</span>
                      <span className="info-value">{selectedObj.Awards}</span>
                    </div>
                    <div className="info-item">
                      <span className="info-title">IMDB Rating:&nbsp;</span>
                      <span className="info-value">{selectedObj.imdbRating}</span>
                    </div>
                    <div className="info-item">
                      <span className="info-title">IMDB Votes:&nbsp;</span>
                      <span className="info-value">{selectedObj.imdbVotes}</span>
                    </div>
                    <div className="info-item">
                      <span className="info-title">Metascore:&nbsp;</span>
                      <span className="info-value">{selectedObj.Metascore}</span>
                    </div>
                    <div className="info-item">
                      <span className="info-title">Genre:&nbsp;</span>
                      <span className="info-value">{selectedObj.Genre}</span>
                    </div>
                    <div className="info-item">
                      <span className="info-title">Country:&nbsp;</span>
                      <span className="info-value">{selectedObj.Country}</span>
                    </div>
                  </div>
                  <div className="movie-link">
                    <a target="_blank" rel="noreferrer" href={`http://www.google.co.in/search?q=${"watch " + selectedObj.Title} ${selectedObj.Released.split(" ")[2]}`} title={`http://www.google.co.in/search?q=${"Watch " + selectedObj.Title} ${selectedObj.Released.split(" ")[2]}`}>
                      Find Movie
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center comment-section">
                <h2>Reviews</h2>
                <form className="scroll-banner justify-content-start">
                  <div className="d-flex flex-column">
                    <RatingComponent />
                    <div className="d-flex flex-row">
                      <input placeholder="Persona" className="input-54" />
                      <input placeholder="What do you think?" className="input-54" />
                      <button type="submit" className="input-54">
                        Post
                      </button>
                    </div>
                  </div>
                </form>
                {true ? (
                  <div className="section-empty">
                    <h2>No ones seems to have left a review for this movie yet</h2>
                    <h3>Leave a review so your voice will be heard first.</h3>
                  </div>
                ) : (
                  Array(10)
                    .fill({ CommentID: 0, UserID: "Ron Swanson", Body: "This movie is mid. The weighted average of reviews from top critics and publications for a given movie, TV show, video game, or album as declared by Metacritic website", createdAt: "01/12/2024", Stars: 3 })
                    .map((comment, index) => (
                      <div key={index} className="d-flex flex-column comment">
                        <div className="d-flex flex-column justify-content-start">
                          <div className="d-flex flex-row justify-content-start">
                            <h2>{comment.UserID}</h2>
                            <p>{timeSince(comment.CreatedAt)}</p>
                          </div>
                          <p>{comment.Body}</p>
                        </div>
                      </div>
                    ))
                )}
                <div className="margin-section">
                  <p>Powered by ROY.</p>
                  <p>2024</p>
                </div>
              </div>
            </>
          )}

          <div className="ui horizontal list">
            {collectionGenres
              .filter((a) => currentGenres.includes(a.toUpperCase()))
              .map((g) => (
                <div key={g} className="ui basic label" onClick={() => handleGenreChange(g)}>
                  {g}
                </div>
              ))}
            {collectionRated
              .filter((a) => currentRating.includes(a.toUpperCase()))
              .map((g) => (
                <div key={g} className="ui basic label" onClick={() => HandleRatingChange(g)}>
                  {g}
                </div>
              ))}
            {currentDirector
              .filter((a) => currentDirector.includes(a.toUpperCase()))
              .map((g) => (
                <div key={g} className="ui basic label" onClick={() => handleDirectorChange(g)}>
                  {g}
                </div>
              ))}
            {currentActor
              .filter((a) => currentActor.includes(a.toUpperCase()))
              .map((g) => (
                <div key={g} className="ui basic label" onClick={() => handleActorChange(g)}>
                  {g}
                </div>
              ))}
            <div className="ui basic label">{collection.length}&nbsp;Movies</div>
          </div>
          <ul className="movie-section">
            {collection.slice((currentPage - 1) * PerPage, (currentPage - 1) * PerPage + PerPage).map((movie) => (
              <li role="button" id={movie.id} key={movie.id} className={selectedObj.id === movie.id ? "movie-button active-movie" : "movie-button"} onClick={() => selectItem(movie.id)}>
                <div className="movie-section-item">
                  <img src={`assets/Movie_Poster_Dataset/${movie.Released.split(" ")[2]}/${movie.imdbID}.jpg`} alt={`${movie.Title} movie Poster`} />
                  <h5>
                    {movie.Title} - {movie.Rated}
                  </h5>
                  <h6>{`${movie.Released.split(" ")[2]} ${movie.Runtime.split(" ")[0]}min ${movie.Director}`}</h6>
                </div>
              </li>
            ))}
          </ul>
          <div className="dock-bottom">
            <div className="scroll-banner justify-content-center">
              <button className="button-54" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                Prev
              </button>
              {currentPage > Math.ceil(maxPagesToShow / 2) && (
                <button className="button-54" onClick={() => handlePageChange(1)}>
                  1
                </button>
              )}
              {currentPage > Math.ceil(maxPagesToShow / 2) && <span>...</span>}
              {getVisiblePages().map((page) => (
                <button key={page} onClick={() => handlePageChange(page)} className={page === currentPage ? "button-54 active1" : "button-54"}>
                  {page}
                </button>
              ))}
              {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && <span>...</span>}
              {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && (
                <button className="button-54" onClick={() => handlePageChange(totalPages)}>
                  {totalPages}
                </button>
              )}
              <button className="button-54" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                Next
              </button>
            </div>
          </div>
        </div>
        <div className="tab-pane fade show" id="stats-tab-pane">
          <h1 className="text-center">Movies Linear Regression</h1>
          <h5 className="text-center">1980 - 2015</h5>
          <div className="px-2">
            <Chart chartEvents={chartEvents} chartType="LineChart" width="100%" height="25vh" style={{ margin: "0", padding: "0" }} data={graphData} options={graphOptions} legendToggle />
          </div>
          <Dropdown
            placeholder="Y-Axis"
            multiple
            selection
            options={["Box_office", "Awards", "imdbRating", "Metascore"].map((b) => {
              return { text: b, value: b, key: b, onClick: () => handleGraphChange(b) };
            })}
          />
        </div>
      </div>
    </section>
  );
};

export default Movies;
